/* eslint-disable @typescript-eslint/no-explicit-any */
import { Response } from "../api.interface";
import { UserCenterAccountResponse } from "./profile.interface";
import newColorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";
import type { OrderStatisticsInfoVO } from "./profile.interface";

/**
 * 获取用户基本信息和账户信息
 * @param types: 返回数据类型POINTS(1, "积分"),
WISH_PRODUCT(2, "收藏商品"),
COUPON(3, "优惠券"),
WISH_SHOP(4, "收藏店铺"),
WAIT_PAY(5, "待支付"),
USER_INFO(6, "用户信息"),
MEMBER_GRADE(7, "会员等级"),
ADDRESS_INFO(8,"地址信息")
ORDER_NUM(9,"订单数量")   待付款+待收货 
 */
export const fetchUserCenterAccount = (
  types: number[],
): Promise<Response<UserCenterAccountResponse>> => {
  return newColorAxiosInstance
    .get<Response<UserCenterAccountResponse>>(`user_account_get`, {
      params: { types: types },
    })
    .then((response: any) => response.data)
    .catch((err: any) => err);
};

// 获取用户未读消息角标信息
export const fetchGetMsgEntrance = (): Promise<Response<number>> => {
  return newColorAxiosInstance
    .post<Response<number>>("user_message_getUnreadCount", undefined, {
      params: { appName: "C", languageCode: "en_US" },
    })
    .then((response: any) => response.data)
    .catch((err: any) => err);
};

// 获取用户订单统计信息
export const fetchGetOrderStatisInfo = (): Promise<Response<OrderStatisticsInfoVO>> => {
  return newColorAxiosInstance
    .get<Response<OrderStatisticsInfoVO>>("user_order_getStatisticsInfo")
    .then((response: any) => response.data)
    .catch((err: any) => err);
};

// 请求邮件退订
export const fetchEmailUnsubscribe = (token: string): Promise<Response<boolean>> => {
  return newColorAxiosInstance
    .post<Response<boolean>>("user_settings_cancelNotification", undefined, { params: { token } })
    .then((response: any) => response.data)
    .catch((err: any) => err);
};
