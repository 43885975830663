/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useRef, useCallback, useEffect } from "react";
import style from "./index.module.scss";

const AutoCode = ({ onValueChange, onComplete }: any) => {
  const [codes, setCodes] = useState<any>(Array.from({ length: 6 }, () => ""));
  const inputsRef: any = useRef([]);

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);
  useEffect(() => {
    onValueChange?.(codes.join(""));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codes]);

  const handleOnFocus = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  }, []);

  // Change 事件
  const handleChangeFn = useCallback(
    (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.target.value.match(/[0-9]{1}/) ? event.target.value : "";

      // 如果输入有效值, 则自动聚焦到下一个输入框
      if (currentValue) {
        inputsRef.current[index + 1]?.focus();
      }

      setCodes((pre: any) => {
        const newData = [...pre];
        newData[index] = currentValue;
        return newData;
      });
    },
    [],
  );

  // 删除事件
  const handleDelete = useCallback(
    (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      const { key } = event;
      // 是否按下删除键, 否提前结束
      if (key !== "Backspace") {
        return;
      }
      // 1. 如果当前输入框有值, 则删除当前输入框内容
      if (codes[index]) {
        setCodes((pre: any) => {
          const newData = [...pre];
          newData[index] = "";
          return newData;
        });
      } else if (index > 0) {
        // 2. 如果当前输入框没有值(考虑下边界的情况 index === 0): 则删除上一个输入框内容, 并且光标聚焦到上一个输入框
        setCodes((pre: any) => {
          const newData = [...pre];
          newData[index - 1] = "";
          onValueChange?.(newData.join(""));
          return newData;
        });
        inputsRef.current[index - 1].focus();
      }
    },
    [codes, onValueChange],
  );

  // 粘贴事件
  const handlePaste = useCallback((event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData("Text"); // 读取剪切板数据
    const pastNum = pastedValue.replace(/[^0-9]/g, ""); // 去除数据中非数字部分, 只保留数字

    // 重新生成 codes: 6 位, 每一位取剪切板对应位置的数字, 没有则置空
    const newData = Array.from({ length: 6 }, (_, index) => pastNum.charAt(index) || "");

    setCodes(newData); // 修改状态 codes

    // 光标要聚焦的输入框的索引, 这里取 pastNum.length 和 5 的最小值即可, 当索引为 5 就表示最后一个输入框了
    const focusIndex = Math.min(pastNum.length, 5);
    inputsRef.current[focusIndex]?.focus();
  }, []);

  // 修改状态 codes
  const handleChange = useCallback(
    (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = event.target.value.match(/[0-9]{1}/) ? event.target.value : "";
      // 如果输入有效值, 则自动聚焦到下一个输入框
      if (currentValue) {
        inputsRef.current[index + 1]?.focus();
      }
      setCodes((pre: any) => {
        let newData = [...pre];
        if (Array.isArray(index)) {
          newData = index;
        }
        if (typeof index === "number") {
          newData[index] = currentValue;
        }
        // 处理 onComplete
        if (newData.every(Boolean) && onComplete) {
          if (newData.join("").length == 6) {
            onComplete(newData.join(""));
          }
        }
        if (newData.join("").length > 6) {
          // onValueChange?.(newData.join(""));
          return pre;
        }
        return newData;
      });
    },
    [onValueChange, onComplete],
  );

  return (
    <div className={style.autoCode}>
      {codes.map((value: any, index: any) => (
        <input
          type="number"
          key={index}
          value={value}
          maxLength={1}
          className={style.autoCodeInput}
          onPaste={handlePaste}
          onFocus={handleOnFocus}
          onKeyDown={handleDelete.bind(null, index)}
          onChange={handleChange.bind(null, index)}
          ref={(ele) => (inputsRef.current[index] = ele)}
        />
      ))}
    </div>
  );
};

export default AutoCode;
