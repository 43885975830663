/* eslint-disable import/no-anonymous-default-export */
export default {
  Login: {
    LOGIN_APPID: "80001", // 登录appid
    NEW_USER: "new", // 登录判断是否 新用户 标识
    OLD_USER: "old", // 登录判断是否 老用户 标识
    PHONE_LOGIN: "phone", // 登录判断是否 手机登录 标识
    EMAIL_LOGIN: "email", // 登录判断是否 邮箱登录 标识
    USER_SUCCESS_ACCOUNT: "_lsa", // 老用户登录用户数据 account key
    MIND_SESSION_STORAGE: "mind_storage", // 心智临时数据
    MIND_LOGIN_SESSION_STORAGE: "mind_login_storage", // 挽留心智数据 优惠券图片等等
    COUNTRY_LIST: "country_list", // 国家手机号数据区分
    STEP_CODE: "_stepCode"
  },
  LoginTypeEnum: {
    ACCOUNT: 1, // 账号登录(Account login)
    MOBILE: 2, // 手机号登录(Mobile phone number login)
    EMAIL: 3, // 邮箱登录(Email Login)
    WECHAT: 5, // 微信登录(WeChat login)
    APPLE: 23, // Apple账号登录(Apple account login)
    FACEBOOK: 24, // Facebook账号登录(Facebook account login)
    GOOGLE: 25, // Google账号登录(Google account login)
  },
  VerifyCodeSceneEnum: {
    REGISTER: 1, // 账号注册或平台账号注册(Account registration or platform account registration)
    LOGIN: 2, // 登录(Log in)
    BIND_MOBILE_OR_EMAIL: 3, // 绑定手机号(Bind mobile phone number)
    MODIFY_MOBILE_OLD: 4, // 修改手机号(原手机号发送验证码)(Modify mobile phone number (original mobile phone number will send verification code))
    MODIFY_MOBILE_NEW: 5, // 修改手机号(新手机号发送验证码)(Modify mobile phone number (new mobile phone number will send verification code))
    MODIFY_PASSWORD: 6, // 修改密码(change Password)
    RETRIEVE_PASSWORD: 7, // 找回密码(Retrieve password)
    MODIFY_EMAIL: 8, // 修改邮箱(原手机号发送验证码)(Modify email (original mobile phone number to send verification code))
    PERSON_REAL_NAME: 9, // 个人实名认证(Personal real-name authentication)
    ONE_STEP_MODIFY_MOBILE_NEW: 10, // 一步修改手机号(新手机号发送验证码)(Modify mobile phone number in one step (new mobile phone number will send verification code))
    MFA_SECOND_CONFIRM: 10, // MFA二验(MFA secondary verification)
  },
  MindScopeEnum: {
    HOME_PAGE: 1, // 主页
    CATEGORY: 2, // 分类
    CART: 3, // 购物车
    ME: 4, // 我的
    SEARCH: 5, // 搜索
    CHECKOUT_PAGE: 6, // 结账页面
    SIGN_IN_REGISTER: 7, // 登录注册
    NEW_USER_REGISTER: 8, // 新用户登录挽留弹框
  },
  // 风控场景
  RiskEnum: {
    SCENE_BINDING: 21, // 用户输入手机或邮箱
    SCENE_AUTHENTICATION: 22, // 验证（验证用户已有的手机或邮箱）
    CODE_FREEZE: "15001", // 被冻结
    CODE_RISK: "15000", // 被风控
  },
};
