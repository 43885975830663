export const LoginEnum = {
  LOGIN_APPID: "80001",
};

export const VerifyCodeSceneEnum = {
  REGISTER: 1, // 账号注册或平台账号注册(Account registration or platform account registration)
  LOGIN: 2, // 登录(Log in)
  BIND_MOBILE_OR_EMAIL: 3, // 绑定手机号(Bind mobile phone number)
  MODIFY_MOBILE_OLD: 4, // 修改手机号(原手机号发送验证码)(Modify mobile phone number (original mobile phone number will send verification code))
  MODIFY_MOBILE_NEW: 5, // 修改手机号(新手机号发送验证码)(Modify mobile phone number (new mobile phone number will send verification code))
  MODIFY_PASSWORD: 6, // 修改密码(change Password)
  RETRIEVE_PASSWORD: 7, // 找回密码(Retrieve password)
  MODIFY_EMAIL: 8, // 修改邮箱(原手机号发送验证码)(Modify email (original mobile phone number to send verification code))
  PERSON_REAL_NAME: 9, // 个人实名认证(Personal real-name authentication)
  ONE_STEP_MODIFY_MOBILE_NEW: 10, // 一步修改手机号(新手机号发送验证码)(Modify mobile phone number in one step (new mobile phone number will send verification code))
  MFA_SECOND_CONFIRM: 10, // MFA二验(MFA secondary verification)
};

/*
  图形校验SDK
  Graphics Verification SDK
 */
export const GoogleReCAPTCHAEnum = {
  KEY_ID: "6LeBC-QqAAAAAIVQrWJt0xkFHjwIwr8ZngUnXCuQ", // 秘钥ID
  ACTION_LOGIN: "SIGNUP", // 登录
  FORGET_PASSWORD: "FORGET_PASSWORD", // 忘记密码
  UPDATE_PROFILE: "UPDATE_PROFILE",
};
