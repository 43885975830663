/* eslint-disable no-dupe-else-if */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Box from "@mui/material/Box";
import style from "../../index.module.scss";
import { useEffect, useState } from "react";
import { maskEmail, maskPhone } from "@/app/csr-src/utils/helpers";
import { userDetailRes, bindLoginTypes } from "../../types";
import DeleteAccount from "../deleteAccount/deleteAccount";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const BoxSX = {
  width: "100%",
  height: "60px",
  borderRadius: 1,
  bgcolor: "#F7F8FC",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
};
const BtnSX = {
  fontSize: 16,
  color: "#888B94",
  margin: "0 10px",
};
interface ProsType {
  jumpToDialog: (props: { type: string; flag?: string }) => void; // 跳转到邮箱认证页面
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userInfo: userDetailRes | null; // 用户信息
}
const ContentList = ({ jumpToDialog, userInfo }: ProsType) => {
  const [email, setEmail] = useState<string | null>("Add"); // 邮箱
  const [phone, setPhone] = useState<string | number | null>(""); // 手机 Add
  const [emailFlag, setEmailFlag] = useState<boolean>(false);
  const [addOrEdit, setAddOrEdit] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  useEffect(() => {
    if (userInfo) {
      if (!userInfo.email) {
        setEmail("Add");
      } else if (userInfo.emailVerified == 0 && userInfo.email) {
        setEmail("Unverified");
      } else if (userInfo.emailVerified == 1 && userInfo.email) {
        setEmail(maskEmail(userInfo.email));
      }
      if (userInfo.mobile) {
        setPhone(maskPhone(String(userInfo.mobile)));
      } else {
        setPhone("Add");
      }
      /*
      loginType : 1 2 3 4
      1: 设置过密码
      2: 手机号登录
      3: 邮箱登录
      4: 第三方登录

      1: 手机号登录 - 邮箱未认证->没有密码;  显示: no password;
      2: 手机号登录 - 邮箱已认证->没有密码;  显示: add password;
      3: 邮箱登录 - change password;
      4: 第三方登录 - 邮箱未认证->没有密码;  显示: no password;
      5: 第三方登录 - 邮箱已认证->没有密码;  显示: add password;
      6: 第三方登录 - 邮箱已认证->有密码;  显示: change password;
      7: 手机号登录 - 邮箱已认证->有密码;  显示: change password;
      
      */

      if (userInfo.bindLoginTypes && userInfo.bindLoginTypes?.length > 0) {
        const bindLogin: bindLoginTypes[] = userInfo?.bindLoginTypes;

        let hasLoginType1 = false;
        let hasLoginType3 = false;

        bindLogin.forEach((item: any) => {
          if (item.status === 1) {
            if (item.loginType === 1) {
              hasLoginType1 = true;
            } else if (item.loginType === 3) {
              hasLoginType3 = true;
            }
          }
        });

        if (hasLoginType1) {
          // 当前账号有密码
          setEmailFlag(true);
          setAddOrEdit("changePassword");
        } else if (!hasLoginType3) {
          // 当前账号没有认证邮箱
          setEmailFlag(false);
          setAddOrEdit("noPassword");
        } else if (hasLoginType3 && !hasLoginType1) {
          // 当前账号认证邮箱 没有密码
          setEmailFlag(true);
          setAddOrEdit("addPassword");
        }
      }
    }
  }, [userInfo]);
  interface JumpProps {
    type: string;
    flag?: string;
  }
  const emailJumpToDialog = () => {
    let data: JumpProps = { type: "", flag: "" };
    email == "Unverified" ? (data = { type: "UnverifiedEmail", flag: "Unverified" }) : null;
    email == "Add" ? (data = { type: "AddEmail", flag: "Add" }) : null;
    email != "Add" && email != "Unverified" ? (data = { type: "ChangeEmail", flag: "Add" }) : null;
    jumpToDialog(data);
  };

  const phoneJumpToDialog = () => {
    phone == "Add" && jumpToDialog({ type: "addPhone" });
    phone != "Add" && jumpToDialog({ type: "changePhone" });
  };
  const accountJumpToDialog = () => {
    setOpenDialog(true);
  };
  return (
    <div className={style.content__list}>
      <Box sx={BoxSX} onClick={emailJumpToDialog}>
        <div className={style.content__list__title}>Email</div>
        <div className={style.content__list__footer}>
          <span
            className={
              (style.footer__text,
              email == "Add" ? style.footer__text__add : "",
              email == "Unverified" ? style.email_text_unverified : "",
              email != "Add" && email != "Unverified" ? style.email_text_black : "")
            }
            onClick={emailJumpToDialog}
          >
            {email == "Unverified" ? (
              <span
                className={style.email_Unverified}
                style={{ display: "flex", alignItems: "center", fontSize: "16px" }}
              >
                <span style={{ color: "#1a1a1a" }}>{userInfo?.email}</span>
                <span style={{ marginLeft: "10px", color: "#FF4D4F" }}>Verify now</span>
              </span>
            ) : (
              email
            )}
          </span>
          <ArrowForwardIosIcon sx={BtnSX} />
        </div>
      </Box>
      <Box sx={BoxSX} onClick={phoneJumpToDialog}>
        <div className={style.content__list__title}>Phone number</div>
        <div className={style.content__list__footer}>
          <span
            className={
              (style.footer__text,
              phone == "Add" ? style.footer__text__add : undefined,
              phone != "Add" ? style.footer__text__black : undefined)
            }
          >
            {phone}
          </span>
          <ArrowForwardIosIcon sx={BtnSX} />
        </div>
      </Box>
      {emailFlag && (
        <Box sx={BoxSX} onClick={() => jumpToDialog({ type: addOrEdit })}>
          <div className={style.content__list__title}>
            {addOrEdit == "addPassword" ? "Add Password" : "Change Password"}
          </div>
          <div className={style.content__list__footer}>
            <span className={style.footer__text}></span>
            <ArrowForwardIosIcon sx={BtnSX} />
          </div>
        </Box>
      )}
      <Box sx={BoxSX} onClick={accountJumpToDialog}>
        <div className={style.content__list__title}>Delete account</div>
        <div className={style.content__list__footer}>
          <ArrowForwardIosIcon sx={BtnSX} />
        </div>
      </Box>
      <DeleteAccount
        open={openDialog}
        userInfo={userInfo}
        closeFn={() => {
          setOpenDialog(false);
        }}
      />
    </div>
  );
};

export default ContentList;
