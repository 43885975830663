import React from "react";
import style from "../../style.module.scss";
import { Button } from "@mui/material";
import LoginEnum from "@/common-components-src/const/login";

const ReceiveTheCodeDom = ({ closeDialog, freezeAccount }: { closeDialog: () => void, freezeAccount: any }) => {
  return (
    <div className={style.receiveTheCode_wrap}>
      <div className={style.title}>Account Frozen</div>
      <div className={style.list}>
        <p className={style.text}>
            We regret to inform you that your account has been temporarily frozen. During this time, you will not be able to access your account or use our services.
        </p>
        <p className={style.text}>Reason for Freeze:{freezeAccount?.reason}.</p>
        <p className={style.text}>To restore your account, please contact our support team.</p>
        <p className={style.identifier}>Email: {freezeAccount?.email}</p>
        <p className={style.identifier}>Phone number: {freezeAccount?.mobile}</p>
      </div>
      <div className={style.footer}>
        <Button
          sx={{
            width: "74px",
            height: "32px",
            fontSize: "16px",
            margin: "8px 0",
            color: "#fff", // 自定义文字颜色
            backgroundColor: "#CC0C1C", // 自定义背景颜色
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#990915", // 悬停时的背景颜色
            },
            "&.Mui-disabled": {
              backgroundColor: "#FF9FA7",
              color: "#fff",
            },
          }}
          onClick={() => closeDialog && closeDialog()}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default ReceiveTheCodeDom;
