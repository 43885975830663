/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import style from "../../index.module.scss";
import ConfirmDialog from "@/app/csr-src/components/confirmDialog/index";
import { DialogContent, DialogTitle } from "@mui/material";
import  AccountRgiht   from "@/app/csr-src/assets/icons/deleteAccountRight.svg";
import  AccountError  from "@/app/csr-src/assets/icons/deleteAccountError.svg";
import { deleteAccountApi } from "@/app/csr-src/apis/profile/accountSetting.api";
import { fetchGetOrderStatisInfo } from "@/app/csr-src/apis/profile/profile.api";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import UKToast from "@/common-components-src/components/UKToast/index";
import UKLoadingIcon from "@/common-components-src/components/UKLoadingIcon";

const DeleteAccount = ({ open, closeFn, userInfo }: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emailVerification, setEmailVerification] = useState(false);
  const [orderVerification, setOrderVerification] = useState(false);

  useEffect(() => {
    orderVerified();
  }, []);

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  useEffect(() => {
    onUserInfoChange();
  }, [userInfo]);

  const orderVerified = async () => {
    const res: any = await fetchGetOrderStatisInfo();
    console.log("djh-orderinfo", res);
    setIsLoading(false);
    if (res && res.code === "200") {
      setOrderVerification(res.data?.unpaidCount <= 0 && res.data?.waitRecCount <= 0 && res.data?.afterSaleCount <= 0 );
    } else {
      setOrderVerification(false);
    }
  };

  const onUserInfoChange = () => {
    if (userInfo) {
      if (!userInfo.email) {
        setEmailVerification(true);
      } else if (userInfo.emailVerified == 0 && userInfo.email) {
        setEmailVerification(false);
      } else if (userInfo.emailVerified == 1 && userInfo.email) {
        setEmailVerification(true);
      }
    }
  };

  const applyToDelete = () => {
    setConfirmDialogOpen(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const closeDialog = () => {
    closeFn && closeFn();
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    const [_, res]: any = await deleteAccountApi();
    if (res && res.data) {
      location.href = "/login"
    } else {
      UKToast.show({
        content: res?.msg || "Failed to delete account!",
        icon: "error",
      });
    }
    closeDialog();
    closeConfirmDialog();
  };

  const DialogStyle = {
    "& .MuiDialog-paper": {
      width: "520px",
      minHeight: "374px",
      borderRadius: "12px",
    },
    "& .MuiDialogTitle-root": {
      fontSize: "18px",
      fontWeight: "bold",
      lineHeight: "24px",
    }
  }

  const ContentStyle = {
    paddingTop: "0",
  }

  const BtnStyle = {
    textTransform: "none",
    width: "472px",
    height: "40px",
    fontSize: "16px",
    color: "#FFFFFF",
    background: "#CC0C1C",
    borderRadius: "4px",
    "&.Mui-disabled": {
      background: "#FF9FA7",
      color: "#FFFFFF",
    },
    "&:hover": {
      background: "#CC0C1C",
    },
  };

  return (
    <UKDialog open={dialogOpen} onClose={closeDialog} showCloseBtn={false} sx={DialogStyle}>
        <DialogTitle>Delete account</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={style.deleteAccount} sx={ContentStyle}>
          {isLoading && <UKLoadingIcon />}
          {!isLoading && (
            <>
              <div className={style.deleteAccount_title}>Apply To Delete Account</div>
              <div className={style.deleteAccount_conduct}>
                Before processing the account deletion request, Joybuy will conduct the following
                verification:
              </div>
              <div className={style.deleteAccount_note}>
                NOTE: Account will NOT BE RECOVERABLE once deleted.
              </div>
              <div className={style.deleteAccount_frame}>
                <div className={style.deleteAccount_detectTitle}>ID verification</div>
                <div className={style.deleteAccount_deltectContent}>
                  {emailVerification ? <AccountRgiht /> : <AccountError />}
                  <span
                    className={
                      emailVerification
                        ? style.deleteAccount_contentRight
                        : style.deleteAccount_contentError
                    }
                  >
                    Ownership for this account has been verified.
                  </span>
                </div>
              </div>
              <div className={style.deleteAccount_frame}>
                <div className={style.deleteAccount_detectTitle}>Account order complete</div>
                <div className={style.deleteAccount_deltectContent}>
                  {orderVerification ? <AccountRgiht /> : <AccountError />}
                  <span
                    className={
                      orderVerification
                        ? style.deleteAccount_contentRight
                        : style.deleteAccount_contentError
                    }
                  >
                    There are no pending orders or returns for this account.
                  </span>
                </div>
              </div>
              <div className={style.deleteAccount_delete}>
                <Button
                  sx={BtnStyle}
                  onClick={applyToDelete}
                  disableElevation
                  disabled={!emailVerification || !orderVerification}
                >
                  Apply to Delete Account
                </Button>
              </div>
              <ConfirmDialog
                  open={confirmDialogOpen}
                  cancelButtonText="Cancel"
                  customButtonText="Delete"
                  handleClose={handleConfirmDelete}
                  handleConfirm={closeConfirmDialog}
              >
                  <div>Are you sure you want to delete your account information?</div>
              </ConfirmDialog>
            </>
          )}
      </DialogContent>
    </UKDialog>
  );
};

export default DeleteAccount;
