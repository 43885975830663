import to from "await-to-js";
import * as T from "./index.interface";
import * as Type from "../api.interface";
import { getEncryptDefaultString } from "@/app/csr-src/utils/helpers";
import newColorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";

// 获取用户信息
export const getUserInfoApi = async (): Promise<Type.RequestTo<any>> => {
  return await to(
    newColorAxiosInstance
      .post<Type.RequestTo<any>>(`user_account_getUserInfo`, undefined)
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};
// 修改用户信息
export const updateUserInfoApi = async (params: any): Promise<Type.RequestTo<any>> => {
  return await to(
    newColorAxiosInstance
      .post<Type.RequestTo<any>>(`user_account_modifyUserInfo`, undefined, { params })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};
// 发送验证码
export const sendOTP = async (
  params: T.SendCodeType,
): Promise<Type.RequestTo<Type.Response<string>>> => {
  const { identifier } = params;
  return await to<Type.Response<string>>(
    newColorAxiosInstance
      .post<Type.RequestTo<Type.Response<string>>>("user_account_sendVerifyCode", undefined, {
        params:{
          ...params,
          identifier: getEncryptDefaultString(identifier),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};
