import style from "../index.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import  Subtract  from "@/app/csr-src/assets/icons/Subtract.svg";
interface PropsType {
  title?: string;
  freezeAccount?: any;
  continueFn?: (v?: { type?: string; flag?: string, success?: true }) => void;
  closeDialog: (v?: {success: boolean}) => void;
}
const successWrap = ({ title, freezeAccount, continueFn, closeDialog }: PropsType) => {
  const successContinue = () => {
    if (freezeAccount?.isRiskControlAccount) {
      continueFn?.({success: true});
      return;
    }
    if (title?.includes("Password") && title?.includes("Change")) {
      closeDialog?.();
    } else {
      continueFn?.({
          type: "accountSecurity",
          flag: "Account security",
        });
    }
  };
  return (
    <div className={style.success__wrap}>
      <div className={style.success__content}>
        <Subtract />
        <span>{title}</span>
      </div>
      <Button
        variant="contained"
        disableElevation
        onClick={successContinue}
        sx={{
          width: "100%",
          height: "40px",
          fontSize: "16px",
          margin: "8px 0",
          backgroundColor: "#fff", // 自定义背景颜色
          color: "#1a1a1a", // 自定义文字颜色
          border: "1px solid #888b94",
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#efefef", // 悬停时的背景颜色
          },
        }}
      >
        Continue
      </Button>
    </div>
  );
};

export default successWrap;
