/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import style from "../../index.module.scss";
import loginEnum from "@/app/csr-src/data/consts/login";
import LoadingIcon from "@/app/csr-src/components/loadingIcon/index";
import cookieUtil from "@/utils/cookieUtils";
import { useState, useEffect } from "react";
import { updatePasswordApi } from "@/app/csr-src/apis/profile/accountSetting.api";
import UKToast from "@/common-components-src/components/UKToast/index";;
import  CheckPsd  from "@/app/csr-src/assets/icons/CheckPsd.svg";
import  NoCheckPsd  from "@/app/csr-src/assets/icons/NoCheckPsd.svg";
import  PasswordError  from "@/app/csr-src/assets/icons/passwordError.svg";
import {
  Button,
  InputLabel,
  IconButton,
  FormControl,
  InputAdornment,
  FormHelperText,
  OutlinedInput,
} from "@mui/material";
interface PropsType {
  jumpToDialog: (v: { type: string; flag?: string; successText?: string }) => void; // 跳转到修改密码对话框
  userInfo: any;
}
const ChangePassword = ({ jumpToDialog, userInfo }: PropsType) => {
  const [oldPassword, setOldPassword] = useState(""); // 旧密码输入的内容
  const [newPassword, setNewPassword] = useState(""); // 新密码输入的内容
  const [newPsdError, setNewPsdError] = useState(""); // 新密码错误提示
  const [confirmPassword, setConfirmPassword] = useState(""); // 再次输入密码内容
  const [confirmPsdError, setConfirmPsdError] = useState(""); // 再次输入密码密码错误提示
  const [isLoading, setIsLoading] = useState(false); // 是否加载中
  const [psdDisabled, setPsdDisabled] = useState(true); // 按钮禁用
  const [showOldPassword, setShowOldPassword] = useState(false); // 是否查看旧密码输入内容
  const [showNewPassword, setShowNewPassword] = useState(false); // 是否查看新密码输入内容
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // 是否查看再次输入密码内容

  useEffect(() => {
    if (/^\s+$/g.test(newPassword as string)) {
      return;
    }
    if (newPasswordValidation(newPassword as string)) {
      if (confirmPassword) {
        if (newPassword == confirmPassword) {
          setConfirmPsdError("");
        } else {
          setConfirmPsdError("The passwords do not match"); // 两次输入的密码不一致
        }
      } else if (confirmPassword == "" && newPassword == "") {
        setConfirmPsdError("");
      }
    }
  }, [newPassword]);
  useEffect(() => {
    if (newPassword && confirmPassword) {
      if (confirmPassword !== newPassword) {
        setConfirmPsdError("The passwords do not match"); // 两次输入的密码不一致
      } else {
        setConfirmPsdError("");
      }
    } else if (newPassword == "" && confirmPassword) {
      setConfirmPsdError("The passwords do not match");
    } else if (newPassword == "" && confirmPassword == "") {
      setConfirmPsdError("");
    } else if (newPassword && confirmPassword == "") {
      setConfirmPsdError("The passwords do not match");
    }
  }, [confirmPassword]);
  useEffect(() => {
    if (oldPassword && newPassword && confirmPassword) {
      if (newPassword == confirmPassword && newPsdError == "" && confirmPsdError == "") {
        setPsdDisabled(false);
      } else {
        setPsdDisabled(true);
      }
    } else {
      setPsdDisabled(true);
    }
  }, [oldPassword, newPassword, confirmPassword, newPsdError, confirmPsdError]);
  // 新密码校验；
  const newPasswordValidation = (psdValue: string): boolean => {
    let flag = false;
    if (psdValue) {
      // setPasswordMsgFlag(true);
      if (!/[0-9]/.test(psdValue)) {
        setNewPsdError("At least one number");
        flag = false;
      } else if (!/[a-zA-Z]/.test(psdValue)) {
        setNewPsdError("At least one letter");
        flag = false;
      } else if (psdValue.length < 8) {
        setNewPsdError("8 characters minimum");
        flag = false;
      } else if (/[0-9]/.test(psdValue) && /[a-zA-Z]/.test(psdValue) && psdValue.length > 8) {
        setNewPsdError("");
        flag = true;
      }
    } else {
      setNewPsdError("");
      flag = true;
    }
    return flag;
  };
  // 点击 Submit 按钮；修改密码
  const psdSignInSubmit = async () => {
    try {
      setIsLoading(true);
      const [err, res]: any = await updatePasswordApi({
        oldPassword: oldPassword,
        newPassword: newPassword,
      });
      if (res && res.success) {
        // successFn();
        cookieUtil.setCookie(loginEnum.LOGIN.USER_SUCCESS_ACCOUNT, userInfo.email,  15, "");
        jumpToDialog({
          type: "passwordSuccess",
          flag: "Change Password",
          successText: "Change Password Successfully",
        });
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg,
          duration: 2000,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error: ", error);
    }
  };

  return (
    <div className={style.change__password}>
      {/* 老密码 */}
      <FormControl fullWidth sx={{ margin: "10px 0" }} variant="outlined" color="info">
        <InputLabel htmlFor="standard-adornment-amount">Old Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          autoComplete="old-password"
          label="Old Password"
          value={oldPassword}
          onChange={(event: any) => setOldPassword(event.target.value)}
          type={showOldPassword ? "password" : "text"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowOldPassword((show) => !show)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
              >
                {showOldPassword ? <NoCheckPsd /> : <CheckPsd />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      {/* 新密码 */}
      <FormControl
        error={!!newPsdError}
        fullWidth
        sx={{ margin: "10px 0" }}
        variant="outlined"
        color="info"
      >
        <InputLabel htmlFor="standard-adornment-amount">New Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          value={newPassword}
          label="New Password"
          onChange={(event: any) => setNewPassword(event.target.value)}
          type={showNewPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowNewPassword((show) => !show)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
              >
                {showNewPassword ? <CheckPsd /> : <NoCheckPsd />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!newPsdError && (
          <FormHelperText sx={{ color: "#FF0F23" }}>
            <div className={style.errorText}>
              <PasswordError />
              <span>{newPsdError}</span>
            </div>
          </FormHelperText>
        )}
      </FormControl>
      {/* 重新输入密码 */}
      <FormControl
        error={!!confirmPsdError}
        fullWidth
        sx={{ margin: "10px 0" }}
        variant="outlined"
        color="info"
      >
        <InputLabel htmlFor="standard-adornment-amount">Confirm Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          value={confirmPassword}
          label="Confirm Password"
          onChange={(event: any) => setConfirmPassword(event.target.value)}
          type={showConfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowConfirmPassword((show) => !show)}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                onMouseUp={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
              >
                {showConfirmPassword ? <CheckPsd /> : <NoCheckPsd />}
              </IconButton>
            </InputAdornment>
          }
        />
        {!!confirmPsdError && (
          <FormHelperText sx={{ color: "#FF0F23" }}>
            <div className={style.errorText}>
              <PasswordError />
              <span>{confirmPsdError}</span>
            </div>
          </FormHelperText>
        )}
      </FormControl>
      <Button
        variant="contained"
        disabled={psdDisabled || isLoading}
        startIcon={isLoading && <LoadingIcon />}
        onClick={psdSignInSubmit}
        sx={{
          width: "100%",
          height: "48px",
          fontSize: "20px",
          margin: "5px 0",
          color: "#fff", // 自定义文字颜色
          backgroundColor: "#FF0F23", // 自定义背景颜色
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            backgroundColor: "#FF475D", // 悬停时的背景颜色
          },
          "&.Mui-disabled": {
            backgroundColor: "#FF9FA7",
            color: "#fff",
          },
        }}
      >
        Submit
      </Button>
    </div>
  );
};

export default ChangePassword;
