export const InputStyle = {
  width: "100%",
  borderRadius: "9px",
  background: "#F9F9F9",
  "& label.Mui-focused": {
    color: "#505259",
    borderRadius: "7px",
  },
  "& input": {
    padding: "12px",
    fontSize: "19px",
    fontWeight: "700",
  },
  "& input::placeholder": {
    padding: "12px",
    fontSize: "19px",
    fontWeight: "400",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0",
    "& fieldset": {
      borderColor: "#F9F9F9",
      borderRadius: "7px",
    },
    "&.Mui-focused fieldset": {
      borderRadius: "7px",
      borderWidth: "1px",
      borderColor: "#eee",
      borderStyle: "solid",
    },
  },
};

export const SelectStyle = {
  "& .Mui-selected": {
    background: "#00000000",
  },
  "& .MuiInputBase-input": {
    paddingLeft: "5px",
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderWidth: "0px",
  },
  "& .MuiInputBase-formControl": {
    borderRadius: "0px",
  },
  "& fieldset": {
    borderColor: "#00000000 !important",
  },
  "& .Mui-focused fieldset": {
    borderColor: "#00000000 !important",
  },
};

export const btnRedDisabled = {
  color: "#fff",
  width: "48%",
  height: "40px",
  fontSize: "16px",
  margin: "5px 0",
  textTransform: "none",
  background: "#FF0F23",
  "&:hover": {
    backgroundColor: "#FF475D",
  },
  "&.Mui-disabled": {
    backgroundColor: "#FF9FA7",
    color: "#fff",
  },
};
export const btnCancelDisabled = {
  width: "48%",
  height: "40px",
  margin: "5px 0",
  fontSize: "16px",
  color: "#1a1a1a",
  textTransform: "none",
  backgroundColor: "#fff",
  border: "1px solid #888B94",
  "&:hover": {
    backgroundColor: "#efefef",
  },
  "&.Mui-disabled": {
    color: "#C2C4CC",
    backgroundColor: "#fff",
    border: "1px solid #C2C4CC",
  },
};

const ITEM_HEIGHT = 38;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null, // 确保菜单从顶部对齐
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
    sx: {
      width: "462px",
      "& .Mui-selected": {
        backgroundColor: "#F9F9F9 !important",
      },
      "::-webkit-scrollbar": { width: "0 !important" },
      "& .MuiMenuItem-root": {
        height: "38px",
        fontSize: "14px",
        lineHeight: "38px",
        color: "#333",
        fontWeight: "400",
        borderBottom: "1px solid #eee",
      },
    },
  },
};
