/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import style from "../../index.module.scss";
import Cancel from "@mui/icons-material/Cancel";
import LoadingIcon from "@/app/csr-src/components/loadingIcon/index";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Button,
  InputLabel,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { setEmailPassword } from "@/app/csr-src/apis/profile/accountSetting.api";
import UKToast from "@/common-components-src/components/UKToast/index";;
import  PasswordError  from "@/app/csr-src/assets/icons/passwordError.svg";
import  PasswordSuccess  from "@/app/csr-src/assets/icons/passwordSuccess.svg";

interface PasswordInputProps {
  onSubmit?: (password: string) => void;
  jumpToDialog: (v: any) => void;
  userInfo: any;
}

// eslint-disable-next-line react/prop-types
const CreatePasswordDom: React.FC<PasswordInputProps> = ({ onSubmit, userInfo, jumpToDialog }) => {
  const [password, setPassword] = useState(""); // 初始密码
  const [confirmPassword, setConfirmPassword] = useState(""); // 再次输入的密码
  const [error, setError] = useState(""); // 初始密码提示
  const [errorConfirmText, setErrorConfirm] = useState(""); // 再次输入密码提示
  const [showPassword, setShowPassword] = useState(false); // 查看密码
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // 查看输入密码
  const [submitFlag, setSubmitFlag] = useState(true); // 按钮状态
  const [focused, setFocused] = useState(false); // 聚焦判断
  const [isLoading, setIsLoading] = useState(false); // 是否加载中
  const [passwordMsgFlag, setPasswordMsgFlag] = useState(false); // 初始密码错误提示元素显示
  const [minimumMsg, setMinimumMsg] = useState(false); // 密码长度提示
  const [atLeastOneNumberMsg, setAtLeastOneNumberMsg] = useState(false); // 最少一个数字
  const [atLeastOneLetterMsg, setAtLeastOneLetterMsg] = useState(false); // 最少一个字母
  const [confirmPasswordMsgFlag, setConfirmPasswordMsgFlag] = useState(false); // 再次输入的密码

  useEffect(() => {
    if (minimumMsg && atLeastOneNumberMsg && atLeastOneLetterMsg && password == confirmPassword) {
      setSubmitFlag(false);
    } else {
      setSubmitFlag(true);
    }
  }, [password, minimumMsg, atLeastOneNumberMsg, atLeastOneLetterMsg, confirmPassword]);

  useEffect(() => {
    if (password.trim() !== "" || /^\s+$/.test(password)) {
      setPasswordMsgFlag(true);
      validatePassword(password);
      if (/[0-9]/.test(password) && /[a-zA-Z]/.test(password) && password.length >= 8) {
        setError("");
      }
    } else {
      setMinimumMsg(false);
      setAtLeastOneNumberMsg(false);
      setAtLeastOneLetterMsg(false);
    }
    if (confirmPassword) {
      if (password == confirmPassword) {
        setConfirmPasswordMsgFlag(true);
        setErrorConfirm("");
      } else {
        setConfirmPasswordMsgFlag(false);
        setErrorConfirm("The passwords do not match"); // 两次输入的密码不一致
      }
    } else if (confirmPassword == "" && password == "") {
      setConfirmPasswordMsgFlag(false);
      setErrorConfirm("");
    }
  }, [password]);

  useEffect(() => {
    if (password && confirmPassword) {
      if (confirmPassword !== password) {
        setErrorConfirm("The passwords do not match"); // 两次输入的密码不一致
        setConfirmPasswordMsgFlag(false);
      } else {
        setErrorConfirm("");
        setConfirmPasswordMsgFlag(true);
      }
    } else if (password == "" && confirmPassword) {
      setErrorConfirm("The passwords do not match");
    } else if (password == "" && confirmPassword == "") {
      setErrorConfirm("");
    } else if (password && confirmPassword == "") {
      setErrorConfirm("The passwords do not match");
      setConfirmPasswordMsgFlag(false);
    }
  }, [confirmPassword]);

  // 输入密码 onChang 事件
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPassword(value);
  };

  // 再次输入密码 onChang 事件
  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };

  // 输入密码校验规则
  const validatePassword = (value: string) => {
    if (!/[0-9]/.test(value)) {
      setError("At least one number");
      setAtLeastOneNumberMsg(false);
    } else {
      setAtLeastOneNumberMsg(true);
    }
    if (!/[a-zA-Z]/.test(value)) {
      setError("At least one letter");
      setAtLeastOneLetterMsg(false);
    } else {
      setAtLeastOneLetterMsg(true);
    }
    if (value.length < 8) {
      setError("8 characters minimum");
      setMinimumMsg(false);
    } else {
      setMinimumMsg(true);
    }
  };

  // 判断是否和初始密码一样
  const validateConfirmPassword = (value: string) => {
    if (password !== value) {
      setErrorConfirm("The passwords do not match");
    } else {
      setErrorConfirm("");
    }
  };
  // 点击 submit
  const handleSubmit = async () => {
    setIsLoading(true);
    if (!error) {
      console.log(password, "password");
      const [err, res]: any = await setEmailPassword({ password: password });
      if (err) {
        setIsLoading(false);
        throw new Error();
      }
      if (res && res.success) {
        UKToast.show({
          icon: "success",
          content: res.msg || "Add Password Successfully",
        });
        jumpToDialog({
          type: "addPasswordSuccess",
          flag: "addPasswordSuccess",
          successText: "Add Password Successfully",
        });
        setIsLoading(false);
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg,
        });
        setIsLoading(false);
      }
    }
  };
  // 查看/隐藏密码
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  // 查看/隐藏二次密码
  const handleShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  // 清空密码
  const closePassword = () => {
    setError("");
    setPassword("");
    setSubmitFlag(true);
  };
  // 清空二次密码
  const closeConfirmPassword = () => {
    setConfirmPassword("");
    setErrorConfirm("");
    setSubmitFlag(true);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleFocus = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  return (
    <div
      style={{
        width: "100% !important",
      }}
      className={style.createPasswordWrap}
    >
      {/* 头部盒子 */}
      <div className={style.title__text}>
        Thanks for coming back - please enter your new password details below.
      </div>
      {/* Input 输入框盒子 */}
      <div className={style.InputBox}>
        <FormControl
          variant="outlined"
          error={!!error}
          color="info"
          sx={{
            width: "450px",
            "&.MuiOutlinedInput-root": {
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: focused
                  ? "1px solid #1a1a1a !important"
                  : "1px solid border-color: rgba(0, 0, 0, 0.23); !important",
              },
            },
          }}
        >
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="Password"
            value={password}
            type={showPassword ? "password" : "text"}
            onChange={handlePasswordChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                {password ? (
                  <>
                    <IconButton onClick={closePassword}>
                      <Cancel sx={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ fontSize: 18 }} />
                      ) : (
                        <Visibility sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </>
                ) : null}
              </InputAdornment>
            }
          />
          {/* <FormHelperText id="component-error-text">{error}</FormHelperText> */}
        </FormControl>

        {passwordMsgFlag ? (
          <ul className={style.errorPasswordBox}>
            <li className={minimumMsg ? style.successMsg : style.errorMsg}>
              {minimumMsg ? <PasswordSuccess /> : <PasswordError />} 8 characters minimum
            </li>
            <li className={atLeastOneNumberMsg ? style.successMsg : style.errorMsg}>
              {atLeastOneNumberMsg ? <PasswordSuccess /> : <PasswordError />} At least one number
            </li>
            <li className={atLeastOneLetterMsg ? style.successMsg : style.errorMsg}>
              {atLeastOneLetterMsg ? <PasswordSuccess /> : <PasswordError />} At least one letter
            </li>
          </ul>
        ) : (
          ""
        )}

        <FormControl
          color="info"
          error={!!errorConfirmText}
          sx={{ width: "450px" }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">Re-enter password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            label="Re-enter password"
            value={confirmPassword}
            type={showConfirmPassword ? "password" : "text"}
            onChange={handleConfirmPasswordChange}
            autoComplete="Re-enter password"
            endAdornment={
              <InputAdornment position="end">
                {confirmPassword ? (
                  <>
                    <IconButton onClick={closeConfirmPassword}>
                      <Cancel sx={{ fontSize: 18 }} />
                    </IconButton>
                    <IconButton
                      onClick={handleShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff sx={{ fontSize: 18 }} />
                      ) : (
                        <Visibility sx={{ fontSize: 18 }} />
                      )}
                    </IconButton>
                  </>
                ) : null}
              </InputAdornment>
            }
          />
          {!!errorConfirmText && (
            <div className={style.error__Text}>
              {confirmPasswordMsgFlag ? <PasswordSuccess /> : <PasswordError />}
              {confirmPasswordMsgFlag ? (
                <span className={style.errorText}>The passwords do not match</span>
              ) : (
                <span>{errorConfirmText}</span>
              )}
            </div>
          )}
          {confirmPasswordMsgFlag && (
            <div className={style.error__Text}>
              <PasswordSuccess />
              <span className={style.errorText}>The passwords do not match</span>
            </div>
          )}
        </FormControl>

        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={submitFlag || isLoading}
          startIcon={isLoading && <LoadingIcon />}
          sx={{
            width: "450px",
            height: "48px",
            fontSize: "20px",
            backgroundColor: "#FF0F23", // 自定义背景颜色
            color: "#fff", // 自定义文字颜色
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#FF475D", // 悬停时的背景颜色
            },
            "&.Mui-disabled": {
              backgroundColor: "#FF9FA7",
              color: "#fff",
            },
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CreatePasswordDom;
