export default {
  JDCloudAppId: "80001",
  LOGIN: {
    LOGIN_APPID: "80001", // 登录appid
    NEW_USER: "new", // 新用户
    OLD_USER: "old", // 老用户
    PHONE_LOGIN: "phone", // 手机登录
    EMAIL_LOGIN: "email", // 邮箱登录
    USER_SUCCESS_ACCOUNT: "_lsa", // 老用户登录用户数据 account
  },
  LoginTypeEnum: {
    ACCOUNT: 1, // 账号登录(Account login)
    MOBILE: 2, // 手机号登录(Mobile phone number login)
    EMAIL: 3, // 邮箱登录(Email Login)
    JD: 4,
    WECHAT: 5, // 微信登录(WeChat login)
    WX_MINI_PROGRAM: 6, // 微信小程序(WeChat applet)
    APPLE: 23, // Apple账号登录(Apple account login)
    FACEBOOK: 24, // Facebook账号登录(Facebook account login)
    GOOGLE: 25, // Google账号登录(Google account login)
  },
  VerifyCodeSceneEnum: {
    REGISTER: 1, // 账号注册或平台账号注册(Account registration or platform account registration)
    LOGIN: 2, // 登录(Log in)
    BIND_MOBILE_OR_EMAIL: 3, // 绑定手机号(Bind mobile phone number)
    MODIFY_MOBILE_OLD: 4, // 修改手机号(原手机号发送验证码)(Modify mobile phone number (original mobile phone number will send verification code))
    MODIFY_MOBILE_NEW: 5, // 修改手机号(新手机号发送验证码)(Modify mobile phone number (new mobile phone number will send verification code))
    MODIFY_PASSWORD: 6, // 修改密码(change Password)
    RETRIEVE_PASSWORD: 7, // 找回密码(Retrieve password)
    MODIFY_EMAIL: 8, // 修改邮箱(原手机号发送验证码)(Modify email (original mobile phone number to send verification code))
    PERSON_REAL_NAME: 9, // 个人实名认证(Personal real-name authentication)
    ONE_STEP_MODIFY_MOBILE_NEW: 10, // 一步修改手机号(新手机号发送验证码)(Modify mobile phone number in one step (new mobile phone number will send verification code))
    MFA_SECOND_CONFIRM: 10, // MFA二验(MFA secondary verification)
  },
  CookiesEnum: {
    MFS_SESSION: "mfs_session", // 登录态
    USER_SUCCESS_ACCOUNT: "_lsa", // 登录账号信息
    RETURN_URL: "returnUrl", // 京东登录返回的路径
  },
};
