import SuccessDom from "./components/Verify";
import CloseIcon from "@mui/icons-material/Close";
import AddPhone from "./components/phoneCode/addPhone";
import ContentList from "./components/content/ContentList";
import AddPassword from "./components/password/addPassword";
import LoginType from "@/common-components-src/const/login";
import ChangePassword from "./components/password/changePassword";
import EmailVerification from "./components/email/emailVerification";

import { useState, useEffect } from "react";
import UKDialog from "@/common-components-src/components/UKDialog/index";
import { getUserInfoApi } from "@/app/csr-src/apis/personal/index.api";
import { AccountTypeEnum } from "@/app/csr-src/data/consts/accountEnum";
import { DialogTitle, IconButton, DialogContent } from "@mui/material";

interface PersonalInfoSetProps {
  open: boolean;
  freezeAccount?: any; // TODO -- 风控冻结参数
  onlyClose?: (isRisk?: boolean) => void;
}

const AccountSetDialog = (props: PersonalInfoSetProps) => {
  const { open, freezeAccount, onlyClose } = props;
  const [flag, setFlag] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<any>({}); // 用户信息
  const [isSuccess, setIsSuccess] = useState<boolean>(false); // 打开成功页面
  const [successText, setSuccessText] = useState<string | null>(""); // 成功页面的文案
  const [dialogFlag, setDialogFlag] = useState<string>(AccountTypeEnum.ACCOUNT_SECURITY); // 弹框类型： accountSecurity emailVerification、addPhone、changePassword
  const [dialogTitle, setDialogTitle] = useState<string>("Account security"); // 弹框标题；

  useEffect(() => {
    setIsOpen(open);
    open && getUSerInfo();
  }, [open]);

  // TODO -- 风控相关的逻辑 ↓ --

  useEffect(() => {
    setUserInfo({
      email: freezeAccount?.identifier,
    })
    
    // 风控 -- 绑定 21 用户输入手机或邮箱
    if (freezeAccount?.scene === LoginType.RiskEnum.SCENE_BINDING){
      if (freezeAccount?.otpType === LoginType.LoginTypeEnum.EMAIL) {
        setDialogTitle("Add Email");
        setFlag(AccountTypeEnum.ADD_EMAIL);
        setDialogFlag(AccountTypeEnum.EMAIL_VERIFICATION);
      }
      if (freezeAccount?.otpType === LoginType.LoginTypeEnum.MOBILE) {
        setDialogTitle("Add Phone number");
        setDialogFlag(AccountTypeEnum.ADD_PHONE);
      }
    }

    // 风控 -- 验证 22（验证用户已有的手机或邮箱）
    if (freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION){
      if (freezeAccount?.otpType === LoginType.LoginTypeEnum.EMAIL) {
        setDialogTitle("Email unverified");
        setFlag(AccountTypeEnum.ADD_EMAIL);
        setDialogFlag(AccountTypeEnum.EMAIL_VERIFICATION);
      }
      if (freezeAccount?.otpType === LoginType.LoginTypeEnum.MOBILE) {
        setDialogTitle("Add Phone number");
        setDialogFlag(AccountTypeEnum.ADD_PHONE);
      }
    }
  }, [freezeAccount])
  // TODO -- 风控相关的逻辑 ↑ --

  const getUSerInfo = async () => {
    try {
      const [err, res] = await getUserInfoApi();
      if (res && res.success) {
        setUserInfo(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 关闭弹框
  const handleClose = () => {
    onlyClose?.();
    setIsOpen(false);
    setIsSuccess(false);
    setTimeout(() => {
      setSuccessText("");
      setDialogTitle("Account security");
      setFlag(AccountTypeEnum.ACCOUNT_SECURITY);
      setDialogFlag(AccountTypeEnum.ACCOUNT_SECURITY);
    }, 500);
  };
  // 切换 弹框；
  const jumpToDialog = ({
    type,
    flag,
    successText,
  }: {
    type: string;
    flag?: string | null;
    successText?: string | null;
  }) => {
    // 跳转到特定页面
    switch (type) {
      // 跳转到列表页面
      case AccountTypeEnum.ACCOUNT_SECURITY:
        setDialogTitle("Account security");
        setFlag(AccountTypeEnum.ACCOUNT_SECURITY);
        setDialogFlag(AccountTypeEnum.ACCOUNT_SECURITY);
        break;
      // 认证邮箱
      case AccountTypeEnum.UNVERIFIED_EMAIL:
        setDialogTitle("Email unverified");
        setFlag(AccountTypeEnum.UNVERIFIED_EMAIL);
        setDialogFlag(AccountTypeEnum.EMAIL_VERIFICATION); // 打开某个弹框页面
        break;
      // 添加邮箱
      case AccountTypeEnum.ADD_EMAIL:
        setDialogTitle("Add Email");
        setFlag(AccountTypeEnum.ADD_EMAIL);
        setDialogFlag(AccountTypeEnum.EMAIL_VERIFICATION);
        break;
      // 修改邮箱
      case AccountTypeEnum.CHANGE_EMAIL:
        setDialogTitle("Change Email");
        setFlag(AccountTypeEnum.CHANGE_EMAIL);
        setDialogFlag(AccountTypeEnum.EMAIL_VERIFICATION);
        break;
      // 添加手机号
      case AccountTypeEnum.ADD_PHONE:
        setDialogTitle("Add Phone number");
        setDialogFlag(AccountTypeEnum.ADD_PHONE);
        break;
      // 修改手机号
      case AccountTypeEnum.CHANGE_PHONE:
        setDialogTitle("Change Phone number");
        setDialogFlag(AccountTypeEnum.CHANGE_PHONE);
        break;
      // 修改密码
      case AccountTypeEnum.CHANGE_PASSWORD:
        setDialogTitle("Change password");
        setDialogFlag(AccountTypeEnum.CHANGE_PASSWORD);
        break;
      // 添加密码
      case AccountTypeEnum.ADD_PASSWORD:
        setDialogTitle("Add password");
        setDialogFlag(AccountTypeEnum.ADD_PASSWORD);
        break;
      // 手机号成功页面
      case AccountTypeEnum.PHONE_SUCCESS:
        setDialogFlag("");
        setIsSuccess(true);
        setDialogTitle("Phone number");
        setSuccessText(successText as string);
        break;
      // 邮箱成功页面
      case AccountTypeEnum.EMAIL_SUCCESS:
        setDialogFlag("");
        setIsSuccess(true);
        setDialogTitle("Email");
        setSuccessText(successText as string);
        break;
      // 修改密码成功
      case AccountTypeEnum.PASSWORD_SUCCESS:
        setDialogFlag("");
        setIsSuccess(true);
        setDialogTitle("Change Password");
        setSuccessText(successText as string);
        break;
      case AccountTypeEnum.ADD_PASSWORD_SUCCESS:
        setDialogFlag("");
        setIsSuccess(true);
        setDialogTitle("Add Password");
        setSuccessText(successText as string);
        break;
      default:
        break;
    }
  };
  // 成功后点击submit
  const continueFn = (params?: any) => {
    if (params?.success) {
      onlyClose?.(params?.success);
      return;
    }
    getUSerInfo();
    setIsSuccess(false);
    setDialogTitle("Account security");
    setFlag(AccountTypeEnum.ACCOUNT_SECURITY);
    setDialogFlag(AccountTypeEnum.ACCOUNT_SECURITY);
  };

  return (
    <UKDialog
      open={isOpen}
      showCloseBtn={false}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "510px", // 设置自定义宽度
          minHeight: "326px",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        {dialogTitle}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers sx={{ borderBottomColor: "#00000000", borderTopColor: "#00000000" }}>
        {dialogFlag === "accountSecurity" && (
          <ContentList jumpToDialog={jumpToDialog} userInfo={userInfo} />
        )}
        {/* 认证邮箱 -- 添加邮箱 */}
        {dialogFlag === "emailVerification" && (
          <EmailVerification freezeAccount={freezeAccount} userInfo={userInfo} flag={flag} jumpToDialog={jumpToDialog} handleClose={handleClose} clonePop={continueFn} />
        )}
        {/* 认证手机号 -- 添加手机号 */}
        {(dialogFlag === "addPhone" || dialogFlag === "changePhone") && (
          <AddPhone freezeAccount={freezeAccount} pageFlag={dialogFlag} jumpToDialog={jumpToDialog} continueFn={continueFn} />
        )}
        {/* 修改密码 */}
        {dialogFlag === "changePassword" && (
          <ChangePassword userInfo={userInfo} jumpToDialog={jumpToDialog} />
        )}
        {/* 添加密码 */}
        {dialogFlag === "addPassword" && (
          <AddPassword userInfo={userInfo} jumpToDialog={jumpToDialog} />
        )}
        {/* --------------------------------- 成功提示 --------------------------------- */}
        {isSuccess && (
          <SuccessDom
            title={successText as string}
            continueFn={continueFn}
            closeDialog={handleClose}
            freezeAccount={freezeAccount}
          />
        )}
      </DialogContent>
    </UKDialog>
  );
};

export default AccountSetDialog;
