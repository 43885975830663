// 邮箱号码掩饰 方法；
import * as LoginRpc from "@/app/csr-src/apis/profile/accountSetting.api";

import cookieUtil from "@/utils/cookieUtils";
import LoginType from "@/common-components-src/const/login";
import { LoginEnum, GoogleReCAPTCHAEnum } from "@/app/csr-src/data/consts/profile";
import { judgeNewOrOldUserApi } from "@/common-components-src/api/login/index.api";

// 发送验证码并且获取sessionID
export const sendCode = ({ scene, otpType, idPrefix, identifier, phoneOrEmail, previousStepCode }: any) => {
  return new Promise((resolve, reject) => {


    const w: any = window;
    // 风控的情况下 发送验证码 需要传入 previousStepCode; 否则不传入
    const stepCode = previousStepCode ? previousStepCode : null;
    const params: any = {
      scene,
      otpType,
      identifier: identifier,
      appId: LoginEnum.LOGIN_APPID,
      stepCode,
    };
    if (phoneOrEmail == LoginType.Login.PHONE_LOGIN) {
      params.idPrefix = idPrefix;
      judgeNewOrOldUserApi({
        otpType: 2,
        idPrefix: idPrefix,
        identifier: identifier,
      }).then( (res: any) =>{
        if (!res?.success) {
          sendOTPCode(params, "", resolve, reject);
          return;
        }
        if (res && res.data?.wl) {
          sendOTPCode(params, "", resolve, reject);
        } else {
          if (!w.grecaptcha){
            reject({
              success: false,
              data: "",
              msg: "Request Google Grecaptcha Enterprise Error",
            })
            return;
          } else {
            w?.grecaptcha.enterprise.ready(async () => {
              const token = await w?.grecaptcha.enterprise.execute(GoogleReCAPTCHAEnum.KEY_ID, {
                action: GoogleReCAPTCHAEnum.UPDATE_PROFILE,
              });
              sendOTPCode(
                params,
                phoneOrEmail == LoginType.Login.PHONE_LOGIN ? token : "",
                resolve,
                reject,
              );
            });
          }
        }
      }).catch(()=>{
          sendOTPCode(params, "", resolve, reject);
      })
    } else {
      // 如果是邮箱 不走 google 直接发送验证码
      sendOTPCode(params, "", resolve, reject);
    }
  });
};
// 发送验证码
const sendOTPCode = async (
  requestData: any,
  token?: string,
  resolve?: any,
  reject?: any,
) => {
  let data: any = null;
  try {
    if (token) {
      requestData.captchaCode = token;
      requestData.captchaAction = GoogleReCAPTCHAEnum.UPDATE_PROFILE;
    }
    const [err, res]: any = await LoginRpc.sendOTP(requestData);
    if (res && res.success) {
      cookieUtil.setSessionStorage("_stepCode", res.data);
      data = {
        success: true,
        data: res.data,
        msg: res.msg,
      };
    } else {
      data = {
        success: false,
        data: "",
        msg: res.msg,
      };
    }
  } catch (error) {
    console.log(error);
  }
  return resolve(data);
};
