/* eslint-disable prettier/prettier */
import style from './index.module.scss'
import { Dialog, DialogTitle } from "@mui/material";

interface ConfirmDialogType {
  open: boolean,
  title?: string,
  children: React.ReactNode,
  customButtonTextFontSize?: number;
  customButtonText?: string,
  cancelButtonText?: string, // 新增的取消按钮文本
  handleClose: () => void, // 关闭弹窗
  handleConfirm?: () => void, // 新增的取消按钮回调
}

const DialogTitleStyles = {
  fontSize: "16px",
  lineHeight: "20px",
  padding: 0,
  marginBottom: "8px"
}

const DialogContainerStyles = {
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "520px",
      minHeight: "128px",
      borderRadius: "12px",
      display: "flex",
      flexDirection: "column",
    }
  }
}

const ConfirmDialog = ({ open, title, children, handleClose, customButtonText, cancelButtonText, handleConfirm }: ConfirmDialogType) => {
  const buttonClassName = cancelButtonText ? style.twoButtonDialogButton : style.dialogButton;
  return <>
    <Dialog
      open={open}
      onClose={handleConfirm || handleClose}
      sx={DialogContainerStyles}
    >
      <div className={style.textContainer}>
        {title && <DialogTitle sx={DialogTitleStyles}>{title}</DialogTitle>}
        {children}
      </div>
      <div className={style.buttonContainer}>
        {cancelButtonText && (
          <button onClick={handleConfirm || handleClose} className={buttonClassName}>
            {cancelButtonText}
          </button>
        )}
        <button onClick={handleClose} className={buttonClassName}>
          {customButtonText ? customButtonText : "OK"}
        </button>
      </div>
    </Dialog >
  </>
}

export default ConfirmDialog;