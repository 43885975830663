/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@mui/material";
import style from "../../index.module.scss";
const ChangeEmail = ({ userInfo, goAddEmail }: any) => {
  const handleSubmit = () => {
    goAddEmail && goAddEmail();
  };
  return (
    <div className={style.change__email}>
      <div className={style.email__text}>{userInfo.email}</div>
      <div className={style.change__title__text}>Email has been verified Successful</div>
      <Button
        disableElevation
        variant="contained"
        onClick={handleSubmit}
        sx={{
          width: "100%",
          height: "40px",
          fontSize: "16px",
          marginTop: "30px",
          backgroundColor: "#fff", // 自定义背景颜色
          color: "#1a1a1a", // 自定义文字颜色
          textTransform: "none",
          boxShadow: "none",
          border: "1px solid #888B94",
          "&:hover": {
            backgroundColor: "#efefef", // 悬停时的背景颜色
          },
        }}
      >
        Change
      </Button>
    </div>
  );
};

export default ChangeEmail;
