import { useEffect, useState } from "react";
import AddEmail from "./addEmailDom";
import ChangeEmail from "./changeEmailDom";
import style from "../../index.module.scss";
import UnverifiedEmail from "./unverifiedEmailDom";
import { AccountTypeEnum } from "@/app/csr-src/data/consts/accountEnum";


const EmailVerification = ({ flag, userInfo, jumpToDialog, handleClose, freezeAccount, clonePop }: any) => {
  const [pageFlag, setPageFlag] = useState("");

  useEffect(() => {
    flag && setPageFlag(flag);
  }, [flag]);

  // 跳转到修改邮箱页面
  const goChangeEmail = () => {
    jumpToDialog?.({ type: AccountTypeEnum.CHANGE_EMAIL });
  };
  const goAddEmail = () => {
    setPageFlag(AccountTypeEnum.EDIT_EMAIL);
  };
  // 跳转到某个页面
  const continueFn = (data: { type: string; flag: string; successText: string }) => {
    jumpToDialog(data);
  };
  return (
    <div className={style.email__wrap}>
      {/* --------------------------------- 添加邮箱 --------------------------------- */}
      {(pageFlag == AccountTypeEnum.ADD_EMAIL || pageFlag == AccountTypeEnum.EDIT_EMAIL) && (
        <AddEmail freezeAccount={freezeAccount} userInfo={userInfo} pageFlag={pageFlag} continueFn={continueFn} handleClose={handleClose} clonePop={clonePop} />
      )}
      {/* --------------------------------- 认证邮箱 --------------------------------- */}
      {pageFlag == AccountTypeEnum.UNVERIFIED_EMAIL && (
        <UnverifiedEmail
          freezeAccount={freezeAccount}
          userInfo={userInfo}
          continueFn={continueFn}
          goChangeEmail={goChangeEmail}
        />
      )}
      {/* --------------------------------- 修改邮箱 --------------------------------- */}
      {pageFlag == AccountTypeEnum.CHANGE_EMAIL && <ChangeEmail userInfo={userInfo} goAddEmail={goAddEmail} />}
    </div>
  );
};

export default EmailVerification;
