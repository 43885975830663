"use client";
import React from "react";
import NewLoginDialog from "./index";
import loginConfig from "@/constants/loginConfig";
import cookieUtils from "@/utils/cookieUtils";
import NewLoginPageDialog from "./index.page";

import { createRoot } from "react-dom/client";
import { clearAll } from "@/utils/addressUtils";
import { outLoginToC, getUserInfoApi, getLoginInfoApi } from "@/common-components-src/api/login/index.api";

export default class NewJDILogin {
  isOpenLogin = null;
  constructor() {
    this.root = document.createElement("div");
    document.body.appendChild(this.root);
    this.forgetPsdRef = React.createRef(); // 通过ref
    this.userInfoPromise = null; // 用户信息
    this.props = {
      handleClose: () => {
        const that = this;
        that.isOpenLogin = false;
      },
    };
    // this.
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new NewJDILogin();
    }
    return this.instance;
  }

  // 渲染登录页面
  renderLoginPage(node, callback = (props) => {}, props = { isRefresh: true, isReturnUrl: true }) {
    if (node instanceof Element) {
      console.log("root:", node);
      const root = createRoot(node);
      root.render(
        React.createElement(NewLoginPageDialog, {
          ref: this.forgetPsdRef,
          open: true,
          IndexProps: null,
          callback: callback ? callback : null, // 传递回调函数
          props: props ? props : null, // 传递参数
          closeFn: this.props.handleClose,
        }),
      );
    }
  }

  /**
   * @param callback 登录弹框回调函数
   * @param props 传入参数 isRefresh：传入false登陆成功后禁止刷新页面；
   */
  // 打开登录弹框
  async openLoginDialog(callback = (props) => {}, props = { isRefresh: true, isReturnUrl: false }) {
    if (this.isOpenLogin) return;
    this.isOpenLogin = true;
    const res = await this.getUserInfo();
    const account = cookieUtils.getCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT);
    if (res.isLoggedIn) return;

    if (account) {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 邮箱正则表达式
      let params;
      if (emailRegex.test(account)) {
        params = {
          identifier: account,
          phoneOrEmail: "email",
          userType: "old",
        };
      } else {
        const [countryCode, localNumber] = account.split("_");
        params = {
          userType: "old",
          phoneOrEmail: "phone",
          idPrefix: countryCode,
          identifier: localNumber,
        };
      }
      const root = createRoot(this.root);
      root.render(
        React.createElement(NewLoginDialog, {
          ref: this.forgetPsdRef, // 传递ref
          IndexProps: params, // 传递参数
          open: true, // 打开对话框
          callback: callback ? callback : null, // 传递回调函数
          props: props ? props : null, // 传递参数
          closeFn: this.props.handleClose,
        }),
      );
    } else {
      const root = createRoot(this.root);
      root.render(
        React.createElement(NewLoginDialog, {
          ref: this.forgetPsdRef,
          open: true,
          IndexProps: null,
          callback: callback ? callback : null, // 传递回调函数
          props: props ? props : null, // 传递参数
          closeFn: this.props.handleClose,
        }),
      );
    }
  }

  // 判断是否登录, 如已登录则返回用户信息；
  async getUserInfo() {
    // if (this.userInfoPromise) {
    //   return this.userInfoPromise;
    // } else {
    // }
    this.userInfoPromise = new Promise(async (resolve, reject) => {
      const [err, res] = await getLoginInfoApi({ types: [6] }, { meta: { forceIgnoreLogin: true } });
      if (err) {
        resolve({
          isLoggedIn: false,
          userInfo: null,
        });
        return;
      }

      if (res.success && res.data) {
        resolve({
          isLoggedIn: true,
          userInfo: res.data,
        });
      } else {
        resolve({
          isLoggedIn: false,
          userInfo: null,
        });
      }
    });
    return this.userInfoPromise;
  }

  // 退出登录
  async logOut() {
    try {
      const [err, res] = await outLoginToC();
      if (res && res.success) {
        // cookieUtils.deleteCookie(loginConfig.loginLocalInfo.USER_SUCCESS_ACCOUNT)
        window.JDIheader && window.JDIheader.initUserInfo();
        window.JDIheader && window.JDIheader.initCartNum();
        clearAll();
        location.reload();
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  }
}
