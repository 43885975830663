import JSEncrypt from "jsencrypt";
const PUB_KEY =
  "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCbNpktS9j5qbO6EB54HIYOkJdkIuyUUwgFBGRDE0+97hZDWEhK2UkzycIAomhd16sixgAULYNGGRknWhfHOMG17TUw8ZDFo7qJRAica2VBuJWd1jZylHq4TJc0xza5ZqdnjtHaF8V8ZN7A3RwnuCaTjhJt5BRBZlbe+Lag2R6RHQIDAQAB";

/**
 * 生成唯一 ID
 * @returns 唯一 ID 字符串
 */
export function generateUniqueId(): string {
  return `id_${Math.random().toString(36).substr(2, 9)}`;
}

/**
 * 将日期格式化为 DD/MM/YYYY（英国格式）
 * @param date - Date 对象或时间戳
 * @returns 格式化后的日期字符串
 */
export function formatUKDate(date: Date | number): string {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

/**
 * 将日期格式化为 Sep 20
 * @param date - Date 对象或时间戳
 * @returns 格式化后的日期字符串
 *
 * */
export function getDateShort(date: Date | number): string {
  const d = new Date(date);
  const monthNamesShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthShort = monthNamesShort[d.getMonth()];
  const day = d.getDate();
  return `${day} ${monthShort}`;
}

/**
 * @desc 获取url参数
 * @param path
 * @param params
 * @returns
 */
export function getCurrentRouteParams(): { [key: string]: string } {
  const searchParams = new URLSearchParams(window.location.search);
  const params: { [key: string]: string } = {};

  if (searchParams) {
    for (const [key, value] of Array.from(searchParams.entries())) {
      params[key] = value;
    }
  }

  return params;
}

/**
 * @desc 加密
 */
export const getEncryptDefaultString = (str: string) => {
  if (typeof str === "number") {
    str = `${str}`;
  }
  return encrypt(str);
  // return typeof str === "string" && str.indexOf("*") === -1 ? encrypt(str) : undefined;
};
export function encrypt(args: string) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(PUB_KEY);
  const str = typeof args == "string" ? args : JSON.stringify(args);
  return encodeURIComponent(jsEncrypt.encrypt(str));
}
// 解密
export function decryptLong(args: string) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(PUB_KEY);
  const str: string = typeof args == "string" ? args : JSON.stringify(args);
  console.log("str:", str);
  console.log("jsEncrypt.decrypt(str)", jsEncrypt.decrypt(str));
  return encodeURIComponent(jsEncrypt.decrypt(str));
}
// 邮箱号码掩饰 方法；
export function maskEmail(email: string) {
  if (email.includes("@")) {
    const parts = email.split("@");
    const localPart = parts[0];
    const domainPart = parts[1];
    if (localPart.length <= 2) {
      return localPart + "@" + domainPart;
    }
    const maskedLocalPart =
      localPart[0] + "*".repeat(localPart.length - 2) + localPart[localPart.length - 1];
    return maskedLocalPart + "@" + domainPart;
  } else {
    if (email.length <= 2) {
      return email;
    }
    const firstChar = email[0];
    const lastChar = email[email.length - 1];
    const maskedChars = "*".repeat(email.length - 2);
    return firstChar + maskedChars + lastChar;
  }
}

// 手机号码掩饰 方法；
export function maskPhone(phoneNumber: string) {
  // return phone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
  if (!phoneNumber || typeof phoneNumber !== "string" || phoneNumber.length < 2) {
    return phoneNumber;
  }

  // 处理国际区号（如 +86）
  const isInternational = phoneNumber.startsWith("+");
  const prefix = isInternational ? phoneNumber.slice(0, 2) : ""; // 保留前两位（如 +8）
  const mainNumber = isInternational ? phoneNumber.slice(2) : phoneNumber;

  // 获取第一位和最后一位
  const firstChar = mainNumber[0];
  const lastChar = mainNumber[mainNumber.length - 1];

  // 用 * 替换中间部分
  const maskedPart = "*".repeat(mainNumber.length - 2);

  // 拼接结果
  return prefix + firstChar + maskedPart + lastChar;
}

// 用户信息时间解析方法
export function convertDate(dateString: string) {
  console.log("dateString: ", dateString);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let flag: boolean = false;
  for (let i = 0; i < months.length; i++) {
    if (dateString.includes(months[i])) {
      flag = true;
    }
  }
  if (flag) {
    const dateParts = dateString.split(" ");
    const day = dateParts[0];
    const monthIndex = months.indexOf(dateParts[1]);
    const year = dateParts[2];
    return `${year}-${String(monthIndex + 1).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  } else {
    const dateParts = dateString.split("-");
    const year = dateParts[0];
    const monthIndex = parseInt(dateParts[1]) - 1;
    const day = dateParts[2];
    return `${day} ${months[monthIndex]} ${year}`;
  }
}

// 标准时间转换为  yyyy-nn-mm
export const dateFormat = (newValue: Date) => {
  const date = new Date(newValue);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  // const hours = String(date.getHours()).padStart(2, "0");
  // const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// 名称隐藏 *
export const maskName = (str: string) => {
  const len = str.length;
  if (len === 1) {
    return str;
  } else if (len === 2) {
    return str[0] + "*";
  } else if (len === 3) {
    return str[0] + "*" + str[2];
  } else if (len === 4) {
    return str[0] + "**" + str[3];
  } else if (len === 5) {
    return str.substring(0, 2) + "**" + str.substring(4);
  } else {
    return str.substring(0, 2) + "**" + str.substring(len - 3);
  }
};
