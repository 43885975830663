// 修改账号场景
export const AccountTypeEnum = {
    ACCOUNT_SECURITY: "accountSecurity", // 去账号列表

    ADD_EMAIL: "AddEmail", // 去添加邮箱标识
    EDIT_EMAIL:"EditEmail", // 去编辑邮箱页面
    CHANGE_EMAIL:"ChangeEmail", // 去修改邮箱页面
    UNVERIFIED_EMAIL:"UnverifiedEmail", // 去认证邮箱页面
    EMAIL_VERIFICATION: "emailVerification", // 邮箱认证页面标识
    EMAIL_SUCCESS: "emailSuccess", // 去邮箱成功页面标识

    ADD_PHONE: "addPhone", // 去添加手机页面标识
    CHANGE_PHONE: "changePhone", // 去修改手机页面标识
    PHONE_SUCCESS: "phoneSuccess", // 去手机成功页面标识

    CHANGE_PASSWORD: "changePassword", // 去修改密码页面
    PASSWORD_SUCCESS: "passwordSuccess", // 去密码成功页面标识
    ADD_PASSWORD: "addPassword", // 去添加密码页面
    ADD_PASSWORD_SUCCESS: "addPasswordSuccess", // 去添加密码成功页面标识
  }