/* eslint-disable @typescript-eslint/no-explicit-any */
import AutoCode from "../AutoCode/code";
import style from "../../index.module.scss";
import { useState, useRef } from "react";
import { Button } from "@mui/material";
import { maskEmail } from "@/app/csr-src/utils/helpers";
import { sendCode } from "../../utils/index";
import * as LoginRpc from "@/app/csr-src/apis/profile/accountSetting.api";
import UKToast from "@/common-components-src/components/UKToast/index";;

const UnverifiedEmail = ({ userInfo, goChangeEmail, continueFn, freezeAccount }: any) => {
  const [emailFlag, setEmailFlag] = useState(false); // 邮箱标题
  const [btnDisabled, setBtnDisabled] = useState(false); // 邮箱提交按钮是否禁用
  const [secondsFlag, setSecondsFlag] = useState(false); // 重复新发送验证码按钮状态
  const [completeCodes, setCompleteCodes] = useState(""); // 输入成功后的验证码二维码
  const [resendCode, setResendCode] = useState("60s"); // 倒计时数字
  const [codeError, setCodeError] = useState(""); // 验证码错误提示
  const [seconds, setSeconds] = useState(0);
  const stepCodeRef = useRef("");

  let Timer: any = null;
  let secondsLeft = 60;
  // 倒计时
  const updateTimer = () => {
    setResendCode(`${secondsLeft}s`);
    Timer = setInterval(() => {
      secondsLeft--;
      if (secondsLeft > 0) {
        setSecondsFlag(true);
        setSeconds(secondsLeft);
        setResendCode(`${secondsLeft}s`);
      } else {
        setBtnDisabled(false);
        setSecondsFlag(false);
        setResendCode(``);
        setSeconds(secondsLeft);
        clearInterval(Timer);
      }
    }, 1000);
  };
  // 邮箱认证，发送邮箱验证码
  const emailSendCode = async () => {
    const res: any = await sendCode({
      scene: 3,
      otpType: 3,
      identifier: userInfo.email,
      phoneOrEmail: "email",
    });
    if (res && res.success) {
      stepCodeRef.current = res.data;
      UKToast.show({
        icon: "success",
        content: res.msg || "Verification code sent successfully",
      });
      setBtnDisabled(true);
      setEmailFlag(true);
      // ------------------
      setSeconds(60);
      setResendCode("60s");
      setSecondsFlag(true);
      // ------------------
      updateTimer();
    } else {
      UKToast.show({
        content: res?.msg || "Verification code sent error",
        icon: "error",
      });
    }
  };
  // 验证 -- 验证码
  const emailVerify = async () => {
    const [e, r]: any = await LoginRpc.updateEmailApi({
      otpType: 3,
      // stepCode: res.data,
      stepCode: stepCodeRef.current,
      identifier: userInfo.email,
      optVerifyCode: completeCodes,
    });

    if (r && r.success) {
      stepCodeRef.current = "";
      continueFn &&
        continueFn({
          type: "emailSuccess",
          successText: "Verify successfully",
          flag: "Verify successfully",
        });
    } else {
      UKToast.show({
        icon: "error",
        content: r.msg,
      });
    }
  };

  // 重新发送验证码
  const resendCodeFN = async () => {
    const res: any = await sendCode({
      scene: 3,
      otpType: 3,
      identifier: userInfo.email,
      phoneOrEmail: "email",
    });
    console.log(res, "发送验证码");
    if (res && res.success && res.success) {
      stepCodeRef.current = res.data;
      updateTimer();
    }
  };

  return (
    <div className={style.add__email}>
      {!emailFlag && (
        <div className={style.email__text}>{userInfo.email && maskEmail(userInfo.email)}</div>
      )}
      {emailFlag && (
        <div className={style.title__text}>
          We have sent the code to {userInfo.email && maskEmail(userInfo.email)}
          {/* <span className={style.edit} onClick={emailEdit}>
            Edit
          </span> */}
        </div>
      )}
      {emailFlag && (
        <AutoCode
          error={codeError}
          onComplete={(codes: string) => {
            codes && setBtnDisabled(false);
            setCompleteCodes(codes);
          }}
          onValueChange={(codes: string) => {
            setCompleteCodes(codes);
            if (codes.length != 6) {
              setBtnDisabled(true);
            } else {
              setBtnDisabled(false);
            }
          }}
        />
      )}
      {emailFlag && (
        <div
          className={secondsFlag ? style.ResendCode__time : style.ResendCode}
          onClick={() => {
            if (!secondsFlag) {
              resendCodeFN();
            }
          }}
        >
          Resend code {seconds != 0 ? resendCode : ""}
        </div>
      )}

      <Button
        disableElevation
        variant="contained"
        disabled={btnDisabled}
        onClick={emailFlag ? emailVerify : emailSendCode}
        sx={{
          color: "#fff",
          width: "100%",
          height: "40px",
          fontSize: "16px",
          boxShadow: "none",
          textTransform: "none",
          margin: "10px 0 5px 0",
          background: "#FF0F23",
          "&.Mui-disabled": {
            backgroundColor: "#FF9FA7",
            color: "#fff",
          },
        }}
      >
        Verify
      </Button>
      {!emailFlag && (
        <Button
          disableElevation
          variant="contained"
          onClick={() => goChangeEmail && goChangeEmail()}
          sx={{
            width: "100%",
            height: "40px",
            fontSize: "16px",
            margin: "4px 0",
            backgroundColor: "#fff", // 自定义背景颜色
            color: "#1a1a1a", // 自定义文字颜色
            border: "1px solid #888b94",
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#efefef", // 悬停时的背景颜色
            },
          }}
        >
          Change
        </Button>
      )}
    </div>
  );
};

export default UnverifiedEmail;
