import pino, { Logger, LoggerOptions } from "pino";

const pinoOption: LoggerOptions = {
  timestamp: () => `,"time":"${new Date().toISOString()}"`,
};

// 因为行云限制，目录文件需要放到/export/Logs/下，所以需要判断环境
const logDestination = process.env.NEXT_PUBLIC_APP_ENV === "development" ? "app.log" : "/export/Logs/app.log";
// build时候因为没有/export/Logs目录，所以执行pino.destination(logDestination)会报错，所以需要在build的时候不执行下面一行
export const logger = (!process.env.npm_lifecycle_event?.startsWith("build") &&
  pino(pinoOption, pino.destination ? pino.destination(logDestination) : undefined)) as Logger<never, boolean>;
