"use client";
import to from "await-to-js";
import { getUserAddressList } from "@/common-components-src/api/address/address.api";
import { initAddress, isPostCodeAddress, isAddress, setAddress, setArea } from "@/utils/addressUtils";
import JDILogin from "../newlogin/index.newlogin";
import i18next from "i18next";

export const curPostCodeOrAddress = async (isDetail = false) => {
  const data = await initAddress();
  // 获取缓存postCode
  if (isPostCodeAddress(data)) {
    // 如果缓存的兜底地址 需要展示默认UK
    if (data?.isDefaultLocal) {
      return `Deliver to UK `;
    }
    return `Deliver to ` + data?.postCode;
  }
  if (isAddress(data)) {
    // 商详展示postcode
    if (isDetail) {
      return `Deliver to ` + data?.countyName + " " + data?.postCode;
    }
    return `Deliver to ` + data?.postCode;
  }
  const { isLoggedIn } = await JDILogin.getInstance().getUserInfo();
  if (!isLoggedIn) {
    return `Deliver to UK `;
  }
  // 如果没有缓存，匹配收货地址（默认收货地址、第一天收货地址），（后端排序规则：有默认则默认在第一条）
  const [err, result] = await to(getUserAddressList());
  const list = result?.data?.addressList;
  if (list && list.length > 0) {
    if (isDetail) {
      return `Deliver to ` + list[0]?.countyName + " " + list[0]?.postCode;
    }
    return `Deliver to ` + list[0]?.postCode;
  }
  return `Deliver to UK `;
};
// 没有缓存的时候，获取默认或者第一条作为缓存数据，目前登录、当前缓存被删除的时候需要存储下
export const setDefaultLocalAddress = async () => {
  // 如果有缓存就直接返回
  const data = await initAddress();
  if (data) {
    return;
  }
  // 存缓存，匹配收货地址（默认收货地址、第一天收货地址），（后端排序规则：有默认则默认在第一条）
  const [err, result] = await to(getUserAddressList());
  const list = result?.data?.addressList;
  if (list && list.length > 0) {
    setAddress(list[0]);
    return;
  }
  // 如果没有收货地址则缓存兜底地址
  const defaulePostCode = {
    postCode: "SW1A 1AA",
    provinceId: 30000004,
    cityId: 30000005,
    districtId: 30000006,
    townId: 30000007,
    countryId: 300000,
    isDefaultLocal: true,
  };
  setArea(defaulePostCode);
};

export const curShippingAddress = async () => {
  const data = await initAddress();
  // 获取缓存postCode
  if (isAddress(data)) {
    return data;
  }
  // 如果没有缓存，匹配收货地址（默认收货地址、第一天收货地址），（后端排序规则：有默认则默认在第一条）
  const [err, result] = await to(getUserAddressList());
  const list = result?.data?.addressList;
  if (list && list.length > 0) {
    if (isPostCodeAddress(data)) {
      const matchedIndex = list.findIndex((item) => item.postCode === data?.postCode);
      if (matchedIndex !== -1) {
        return list[matchedIndex];
      }
    }
    return list[0];
  }
  return null;
};
