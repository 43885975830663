/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import to from "await-to-js";
import * as T from "./profile.interface";
import * as Type from "../api.interface";

import { getEncryptDefaultString } from "@/app/csr-src/utils/helpers";
import colorAxiosInstance from "@/common-components-src/api/new-color-axiosInstance";

// 获取用户信息
export const getUserInfoApi = async (): Promise<Type.RequestTo<any>> => {
  return await to(
    colorAxiosInstance
      .post<Type.RequestTo<any>>(`user_account_getUserInfo`, undefined, {})
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取 sessionID
export const getSessionID = (
  requestData: T.sessionProps,
): Promise<T.Response<T.sessionIdResponse>> => {
  return colorAxiosInstance
    .post<T.Response<T.sessionIdResponse>>("user_account_getCaptchaSessionId", undefined, {
      params: { ...requestData },
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err: any) => {
      console.log("sxt-err", err);
    });
};

// 发送验证码
export const sendOTP = async (
  params: T.SendCodeType,
): Promise<Type.RequestTo<Type.Response<string>>> => {
  const { identifier, scene } = params;
  return await to<Type.Response<string>>(
    colorAxiosInstance
      .post<Type.RequestTo<Type.Response<string>>>("user_account_sendVerifyCode", undefined, {
        params: {
          ...params,
          identifier: getEncryptDefaultString(identifier),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 邮箱忘记密码 校验验证码
export const updateEmailApi = async (params: any): Promise<Type.RequestTo<any>> => {
  const { identifier } = params;
  return await to(
    colorAxiosInstance
      .post<Type.RequestTo<any>>("user_account_modifyAccount", undefined, {
        params: {
          ...params,
          identifier: getEncryptDefaultString(identifier),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 旧密码 -- 修改密码
export const updatePasswordApi = async (params: any): Promise<Type.RequestTo<any>> => {
  const { oldPassword, newPassword } = params;
  return await to(
    colorAxiosInstance
      .post<Type.RequestTo<any>>("user_account_modifyPwdByOldPwd", undefined, {
        params: {
          oldPassword: getEncryptDefaultString(oldPassword),
          newPassword: getEncryptDefaultString(newPassword),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 验证码 -- 修改密码
export const codeUpdatePasswordApi = async (params: any): Promise<Type.RequestTo<any>> => {
  const { password, stepCode } = params;
  return await to(
    colorAxiosInstance
      .post<Type.RequestTo<any>>("user_account_modifyPwdByVerifyCode", undefined, {
        params: {
          stepCode,
          password: getEncryptDefaultString(password),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 添加密码
export const setEmailPassword = async (requestData: { password: string }) => {
  const { password } = requestData;
  return await to(
    colorAxiosInstance
      .post<Type.RequestTo<any>>("user_account_setPassword", undefined, {
        params: {
          password: getEncryptDefaultString(password),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 邮箱忘记密码 校验验证码  jnos.account.otp.update
export const checkEmailCodeApi = async (
  params: T.verifyCodeCheckRequest,
): Promise<Type.RequestTo<Type.Response<string>>> => {
  const { email, scene, otpType, stepCode, optVerifyCode } = params;
  return await to<Type.Response<string>>(
    colorAxiosInstance
      .post<Type.Response<string>>("user_account_checkVerifyCode", undefined, {
        params: {
          scene,
          otpType,
          stepCode,
          optVerifyCode,
          identifier: getEncryptDefaultString(email),
        },
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 账号注销
export const deleteAccountApi = async (): Promise<Type.RequestTo<Type.Response<boolean>>> => {
  return await to<Type.Response<boolean>>(
    colorAxiosInstance
      .post<Type.Response<string>>("user_account_delete", undefined, {
        params: {},
        cthr: "1",
      })
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
};

// 获取国家手机号数据
export const getCountryListApi = async () => {
  const res = await to<T.Response<any>>(
    colorAxiosInstance
      .post<T.Response<any>>("user_account_getCountryList", {}, {})
      .then((response: any) => response.data)
      .catch((err: any) => err),
  );
  return res;
};
