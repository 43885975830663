// 加载中图标
import { CircularProgress } from "@mui/material";
type PropsType = {
  sizes?: number;
  sx?: object;
};
const LoadingIcon = ({ sizes = 16, sx = { color: "#fff" } }: PropsType) => {
  return <CircularProgress size={sizes} sx={sx} />;
};
export default LoadingIcon;
