import AutoCode from "../AutoCode/code";
import style from "../../index.module.scss";
import cookieUtil from "@/utils/cookieUtils";
import LoginType from "@/common-components-src/const/login";
import  RedClose  from "@/app/csr-src/assets/icons/redClose.svg";
import  CloseLine from "@/app/csr-src/assets/icons/close_line.svg";
import LoadingIcon from "@/app/csr-src/components/loadingIcon/index";

import {
  Button,
  InputLabel,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { sendCode } from "../../utils/index";
import { useEffect, useState, useRef } from "react";
import { maskEmail } from "@/app/csr-src/utils/helpers";
import UKToast from "@/common-components-src/components/UKToast/index";;
import { btnRedDisabled, btnCancelDisabled } from "../style";
import { AccountTypeEnum } from "@/app/csr-src/data/consts/accountEnum";
import { updateEmailApi } from "@/app/csr-src/apis/profile/accountSetting.api";
import { loginOrRegisterByOTP } from "@/common-components-src/api/login/index.api";
import { extractEmailParams, splitAndProcess } from "@/common-components-src/js/newlogin/utils/login";


const AddEmail = ({ pageFlag, continueFn, handleClose, freezeAccount, clonePop }: any) => {
  const [seconds, setSeconds] = useState(0);
  const [codeError, setCodeError] = useState(""); // 验证码错误提示
  const [resendCode, setResendCode] = useState("60s"); // 倒计时数字
  const [error, setError] = useState<string | null>(""); // 错误提示
  const [completeCodes, setCompleteCodes] = useState(""); // 输入成功后的验证码的值
  const [inputVal, setInputVal] = useState<string | null>(""); // 输入框的值
  const [emailFlag, setEmailFlag] = useState(true); // 打开发送验证码页面或者邮箱验证码页面
  const [isLoading, setIsLoading] = useState(false); // 是否加载中
  const [secondsFlag, setSecondsFlag] = useState(false); // 重复新发送验证码按钮状态
  const [codeDisabled, setCodeDisabled] = useState(true); // 邮箱添加按钮是否禁用
  const [emailDisabled, setEmailDisabled] = useState(true); // 邮箱添加按钮是否禁用
  const urlExtParams = splitAndProcess();
  const Timer = useRef<any>(null);
  const secondsLeft = useRef<any>(60);

  useEffect(() => {
    setSecondsFlag(true);
    setResendCode("60s");
    setEmailDisabled(true);
  }, []);

  // 输入框的值改变
  useEffect(() => {
    if (inputVal && inputVal.trim() !== "") {
      setEmailDisabled(false);
    } else {
      setEmailDisabled(true);
    }
    setError("");
  }, [inputVal]);
  
  // 点击发送邮箱验证码
  const sendEmailCodeFn = () => {
    setIsLoading(true);
    if (emailFlag) {
      if (inputVal && inputVal.trim() !== "") {
        if (validateEmail(inputVal)) {
          const allowedChars = /^[A-Za-z0-9._+-]+@[A-Za-z0-9._+-]+\.[A-Za-z._+-]{2,}$/;
          if (!allowedChars.test(inputVal)) {
            setError("Please enter a valid email address");
          } else {
            emailSendCode(false);
          }
        } else {
          setError("Please enter a valid email address");
          setIsLoading(false);
        }
      } else {
        setError("Please enter your phone number or email address");
        setIsLoading(false);
      }
    } else {
      setError("Please enter your phone number or email address");
      setIsLoading(false);
    }
  };
  // 邮箱校验方法
  const validateEmail = (email: string) => {
    const localPart = email.split("@")[0];
    if (localPart.length < 2 || localPart.length > 64) {
      return false;
    }
    if (email.length > 254) {
      return false;
    }
    return true;
  };
  // 倒计时方法
  const updateTimer = () => {
    setResendCode(`${secondsLeft.current}s`);
    Timer.current = setInterval(() => {
      secondsLeft.current--;
      if (secondsLeft.current > 0) {
        setSeconds(secondsLeft.current);
        setResendCode(`${secondsLeft.current}s`);
      } else {
        setSecondsFlag(false);
        setEmailDisabled(false);
        setResendCode(``);
        setSeconds(secondsLeft.current);
        clearInterval(Timer.current);
      }
    }, 1000);
  };
  // 点击返回列表页面
  const CancelFn = () => {
    if (freezeAccount?.isRiskControlAccount) {
      handleClose?.();
      return;
    }
    continueFn?.({ type: AccountTypeEnum.ACCOUNT_SECURITY, flag: "Account security" });
  };

   // TODO -- 风控相关的逻辑 ↓ --
  useEffect(()=> {
    // 验证的时候 不需要输入弹框 22 
    if (freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION) {
      setIsLoading(false);
      emailSendCode();
      setEmailFlag(false);
    }
  }, [freezeAccount])

  // TODO -- 风控相关的逻辑 ↑ --
  // 发送邮箱验证码
  const emailSendCode = async (flag?: boolean) => {
    // 发送验证码场景标识 
    // TODO -- 风控相关的逻辑 ↓ --
    let scene = null
    if (freezeAccount?.isRiskControlAccount) {
      // 如果被风控的时候发送验证码需要 填入风控的场景标识;
      scene = freezeAccount?.scene;
    } else {
      scene = pageFlag === AccountTypeEnum.ADD_EMAIL ? LoginType.VerifyCodeSceneEnum.BIND_MOBILE_OR_EMAIL : LoginType.VerifyCodeSceneEnum.MODIFY_MOBILE_NEW
    }
    
    // TODO -- 风控相关的逻辑 ↑ --
    const res: any = await sendCode({
      scene: scene,
      otpType: LoginType.LoginTypeEnum.EMAIL,
      phoneOrEmail: LoginType.Login.EMAIL_LOGIN,
      previousStepCode: freezeAccount?.previousStepCode, // 被风控的情况返回的 stepCode, 发送验证码时候传入;
      identifier: freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION ? freezeAccount?.identifier : inputVal,
    });

    if (res && res?.success) {
      cookieUtil.setSessionStorage(LoginType.Login.STEP_CODE, res?.data)
      UKToast.show({ icon: "success", content: "Verification code sent successfully"});
      setEmailDisabled(true); // 修改发送验证码按钮状态
      setEmailFlag(false); // 打开验证码验证页面
      !flag && setCodeDisabled(true);
      setSeconds(60);
      setResendCode("60s");
      setSecondsFlag(true);
      updateTimer();
      setIsLoading(false);
    } else {
      UKToast.show({ icon: "error", content: res.msg });
      setError(res.msg || "Incorrect email");
      setIsLoading(false);
    }
  };
  // 点击 添加/编辑 邮箱
  const emailSignInSubmit = async () => {
    setIsLoading(true);
    if (freezeAccount?.isRiskControlAccount) {
      // 如果是风控账号 过来的情况下
      freezeAccountFn();
    } else {
      updateEmail();
    }
  };

  const freezeAccountFn = async () => {
    const extParams = freezeAccount?.userType === LoginType.Login.NEW_USER ? {extension: JSON.stringify({ ...urlExtParams })} : null;
    const [err, res]: any = await loginOrRegisterByOTP({
      // 如果是验证的话 需要用传入的邮箱 , 否则使用输入的;
      identifier: freezeAccount?.scene === 22  ? freezeAccount?.identifier : inputVal,
      verifyCode: completeCodes, // 验证码
      scene: freezeAccount?.scene, // 风控场景
      otpType: LoginType.LoginTypeEnum.EMAIL, // 邮箱 - 类型
      previousStepCode: freezeAccount?.previousStepCode, // 风控账号的stepCode
      stepCode: cookieUtil.getSessionStorage(LoginType.Login.STEP_CODE),
      ...extParams, // 地推 邀请码 - 参数
    });
    setIsLoading(false);
    if (!res?.success) {
      UKToast.show({ icon: "error", content: res?.msg || "Failed to complete the operation" });
      return;
    }
    clonePop?.({success: true});
    // continueFn?.({ type: AccountTypeEnum.EMAIL_SUCCESS, successText: freezeAccount?.scene === LoginType.RiskEnum.SCENE_BINDING ? "Add Email Successfully" : "Verify successfully" });
  };

  // 正常的 添加编辑邮箱账号
  const updateEmail = async () => {
    try {
      const [err, res]: any = await updateEmailApi({
        identifier: inputVal,
        optVerifyCode: completeCodes,
        otpType: LoginType.LoginTypeEnum.EMAIL,
        stepCode: cookieUtil.getSessionStorage(LoginType.Login.STEP_CODE),
      });
      if (res && res.success) {
        cookieUtil.clearSessionStorage(LoginType.Login.STEP_CODE);
        cookieUtil.clearSessionStorage("_sessionId");
        UKToast.show({
          icon: "success",
          content: res.msg || "success",
        });
        continueFn?.({
            type: AccountTypeEnum.EMAIL_SUCCESS,
            successText: pageFlag == AccountTypeEnum.ADD_EMAIL ? "Add Email Successfully" : "Change Email Successfully",
          });
        setIsLoading(false);
      } else {
        UKToast.show({
          icon: "error",
          content: res.msg || "error",
        });
        setCodeError(res.msg || "Incorrect email");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  }
  return (
    <div className={style.unverified__email}>
      {!emailFlag && (
        <div className={style.email__content}>{!emailFlag && maskEmail(inputVal as string)}</div>
      )}
      {emailFlag && (
        <>
          {/* 输入框 */}
          <FormControl
            color="info"
            sx={{
              width: "100%",
              margin: "16px 0",
              height: "40px",
              border: "1px solid #f9f9f9",
              "& .MuiFormLabel-root": { background: "#fff", padding: "0 5px" },
            }}
            variant="outlined"
            error={!!error}
          >
            <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              value={inputVal}
              placeholder="Enter your email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputVal(e.target.value)}
              sx={{ height: "48px", background: "#fff", borderRadius: "4px" }}
              endAdornment={
                <InputAdornment position="end">
                  {inputVal && (
                    <IconButton
                      onClick={() => {
                        setError("");
                        setInputVal("");
                      }}
                    >
                      <CloseLine />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
            {/* <FormHelperText id="outlined-weight-helper-text">Weight</FormHelperText> */}
          </FormControl>
          {/* 输入框错误提示 */}
          {!!error && (
            <div className={style.error__text}>
              <RedClose />
              <span className={style.red__close__icon}>{error}</span>
            </div>
          )}
          {/* 按钮 */}
          <div className={style.btn__wrap}>
            <Button
              variant="contained"
              disableElevation
              onClick={CancelFn}
              sx={{
                ...btnCancelDisabled,
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={emailDisabled || isLoading}
              startIcon={isLoading && <LoadingIcon />}
              disableElevation
              onClick={sendEmailCodeFn}
              sx={{
                ...btnRedDisabled,
              }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
      {freezeAccount?.isRiskControlAccount && (
          <div className={style.email__content}>{freezeAccount?.identifier}</div>
      )}
      {!emailFlag && (
        <>
          {/* 验证码 */}
          <AutoCode
            error={codeError}
            onComplete={(codes: string) => {
              codes && setCodeDisabled(false);
              setCompleteCodes(codes);
            }}
            onValueChange={(codes: string) => {
              setCompleteCodes(codes);
              if (codes.length != 6) {
                setCodeDisabled(true);
              } else {
                setCodeDisabled(false);
              }
            }}
          />
          {secondsFlag && seconds != 0 ? (
            <span className={style.ResendCode__time}>Resend code {resendCode}</span>
          ) : (
            <span onClick={() => emailSendCode(true)} className={style.ResendCode}>
              Resend
            </span>
          )}
          <div className={style.btn_emailVerify_wrap}>
            <Button
              disableElevation
              variant="contained"
              disabled={codeDisabled || isLoading}
              startIcon={isLoading && <LoadingIcon />}
              onClick={emailSignInSubmit}
              sx={{
                ...btnRedDisabled,
              }}
            >
              {pageFlag === AccountTypeEnum.ADD_EMAIL && !freezeAccount?.isRiskControlAccount ? "Register" : "Continue"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={CancelFn}
              sx={{
                ...btnCancelDisabled,
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      )}
      <div className={style.title__text}>
        {emailFlag
          ? "After entering and cerifying the Email, you can login directly with your Email."
          : "We have sent a confirmation email to you. Please click the link in the email to complete cerification"}
      </div>
    </div>
  );
};

export default AddEmail;
