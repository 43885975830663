import AutoCode from "../AutoCode/code";
import style from "../../index.module.scss";
import cookieUtil from "@/utils/cookieUtils";
import LoginType from "@/common-components-src/const/login";
import CloseLine  from "@/app/csr-src/assets/icons/close_line.svg";
import LoadingIcon from "@/app/csr-src/components/loadingIcon/index";


import {
  Button,
  Select,
  MenuItem,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import { sendCode } from "../../utils/index";
import { useEffect, useRef, useState } from "react";
import { maskPhone } from "@/app/csr-src/utils/helpers";
import UKToast from "@/common-components-src/components/UKToast";
import { btnRedDisabled, btnCancelDisabled } from "../style";
import { InputStyle, SelectStyle, MenuProps } from "../style";
import { AccountTypeEnum } from "@/app/csr-src/data/consts/accountEnum";
import { splitAndProcess } from "@/common-components-src/js/newlogin/utils/login";
import { loginOrRegisterByOTP } from "@/common-components-src/api/login/index.api";
import { updateEmailApi, getCountryListApi } from "@/app/csr-src/apis/profile/accountSetting.api";


const AddPhone = ({ pageFlag, jumpToDialog, freezeAccount, continueFn }: any) => {
  const [seconds, setSeconds] = useState(0);
  const [codeError, setCodeError] = useState(""); // 错误提示
  const [resendCode, setResendCode] = useState("60s"); // 倒计时数字
  const [completeCodes, setCompleteCodes] = useState(""); // 输入成功后的验证码 进行 校验验证码
  const [isShowVerificationInput, setIsShowVerificationInput] = useState(true); // 是否显示验证码输入框
  const [secondsFlag, setSecondsFlag] = useState(false); // 重复新发送验证码按钮状态
  const [addPhoneDisabled, setBtnDisabled] = useState(true); // 邮箱添加按钮是否禁用
  const [sendCodeBtnDisabled, setSendCodeBtnDisabled] = useState(false);
  const [idPrefix, setIdPrefix] = useState<any>(44); // 手机号国家标识
  const [error, setError] = useState<string | null>(""); // 错误提示
  const [inputVal, setInputVal] = useState<string | null>(""); // 输入框的值
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const stepCodeRef = useRef<string | null>(null);
  const countryList = useRef<any>(null);
  const Timer = useRef<any>(null);
  const secondsLeft = useRef<any>(60);
  const urlExtParams = splitAndProcess();

  // 输入框的值改变
  useEffect(() => {
    if (inputVal && inputVal.trim() !== "" && inputVal.length >= 6) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
    setError("");
  }, [inputVal]);

  useEffect(() => {
    getCountryList();
  }, []);
  // 倒计时方法
  const updateTimer = () => {
    setResendCode(`${secondsLeft.current}s`);
    Timer.current = setInterval(() => {
      secondsLeft.current--;
      if (secondsLeft.current > 0) {
        setSeconds(secondsLeft.current);
        setResendCode(`${secondsLeft.current}s`);
      } else {
        setResendCode(``);
        setSecondsFlag(false);
        setSendCodeBtnDisabled(false);
        setSeconds(secondsLeft.current);
        clearInterval(Timer.current);
      }
    }, 1000);
  };
  
  // TODO -- 风控相关的逻辑 ↓ --
  useEffect(()=> {
    // 验证的时候 不需要输入手机号弹框
    if (freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION) {
      sendCodeFn("Resend");
      setIsLoading(true);
      setIsShowVerificationInput(false);
    }
  }, [freezeAccount])

  // TODO -- 风控相关的逻辑 ↑ --

  // 发送验证码
  const sendCodeFn = async (type?: string) => {
    // TODO -- 风控相关的逻辑 ↓ --
    let scene = null
    if (freezeAccount?.isRiskControlAccount) {
      // 如果被风控的时候发送验证码需要 填入风控的场景标识;
      scene = freezeAccount?.scene;
    } else {
      scene = pageFlag === AccountTypeEnum.ADD_PHONE ? LoginType.VerifyCodeSceneEnum.BIND_MOBILE_OR_EMAIL : LoginType.VerifyCodeSceneEnum.MODIFY_MOBILE_NEW
    }

    // TODO -- 风控相关的逻辑 ↑ --
    if (type != "Resend") {
      setIsLoading(true);
    }
    const res: any = await sendCode({
      scene: scene,
      identifier: freezeAccount?.scene === 22  ? freezeAccount?.identifier : inputVal,
      idPrefix: freezeAccount?.scene === 22  ? freezeAccount?.idPrefix : "+" + idPrefix,
      otpType: LoginType.LoginTypeEnum.MOBILE,
      phoneOrEmail: LoginType.Login.PHONE_LOGIN,
      previousStepCode: freezeAccount?.previousStepCode, // 被风控的情况返回的 stepCode, 发送验证码时候传入;
    });
    if (res && res.success) {
      stepCodeRef.current = res.data;
      UKToast.show({ icon: "success", content: res.msg || "Verification code sent successfully" });
      // if (!completeCodes) { setBtnDisabled(true) }
      secondsLeft.current = 60;
      setSeconds(60);
      setResendCode("60s");
      setSendCodeBtnDisabled(true);
      updateTimer();
      setIsShowVerificationInput(false);
      setSecondsFlag(true);
    } else {
      setError(res.msg || "Incorrect phone number");
      UKToast.show({ content: res?.msg, icon: "error" });
    }
    setIsLoading(false);
  };
  // 点击编辑按钮 返回上一页
  const phoneEdit = () => {
    setIsShowVerificationInput(true);
    clearInterval(Timer.current);
    setResendCode(``);
    setSecondsFlag(false);
    secondsLeft.current = 60;
    setSeconds(secondsLeft.current);
    setBtnDisabled(false);
    stepCodeRef.current = null;
  };
  // Sign In
  const phoneSignInSubmit = async () => {
    setIsLoading(true);
    if (isShowVerificationInput) {
      // 发送验证码
      if (inputVal && inputVal.trim() !== "") {
        if (/^\d+$/.test(inputVal) && inputVal.length < 13) {
          sendCodeFn();
        } else {
          setIsLoading(false);
          setError("Incorrect phone number");
        }
      }
    } else {
      // 添加 -- 修改 手机号
      setIsLoading(true);
      if (freezeAccount?.isRiskControlAccount) {
        // 如果是风控账号 过来的情况下
        freezeAccountFn();
      } else {
        updatePhone();
      }
    }
  };

  // 被风控的时候进行验证风控
  const freezeAccountFn = async () => {
    const extParams = freezeAccount?.userType === LoginType.Login.NEW_USER ? {extension: JSON.stringify({ ...urlExtParams })} : null;
    const [err, res]: any = await loginOrRegisterByOTP({
      // 如果是验证的话 需要用传入的邮箱 , 否则使用输入的;
      verifyCode: completeCodes, // 验证码
      scene: freezeAccount?.scene, // 风控场景
      stepCode: stepCodeRef.current,
      otpType: LoginType.LoginTypeEnum.MOBILE, // 邮箱 - 类型
      previousStepCode: freezeAccount?.previousStepCode, // 风控账号的stepCode
      identifier: freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION ? freezeAccount?.identifier : inputVal,
      idPrefix: freezeAccount?.scene === LoginType.RiskEnum.SCENE_AUTHENTICATION ? freezeAccount?.idPrefix : "+" + idPrefix,
      ...extParams, // 地推 邀请码 - 参数
    });
    setIsLoading(false);
    if (!res?.success) {
      UKToast.show({ icon: "error", content: res?.msg || "Failed to complete the operation" });
      return;
    }
    stepCodeRef.current = null;
    cookieUtil.clearSessionStorage("_stepCode");
    continueFn?.({success: true})
    // jumpToDialog?.({
    //   flag: "Verify successfully",
    //   type: AccountTypeEnum.PHONE_SUCCESS,
    //   successText: freezeAccount?.scene === LoginType.RiskEnum.SCENE_BINDING ? "Add Phone Successfully" : "Verify successfully"
    // });
  }

  // 正常添加或者修改手机号
  const updatePhone = async () => {
    const [err, res]: any = await updateEmailApi({
      otpType: 2,
      identifier: inputVal,
      idPrefix: "+" + idPrefix,
      optVerifyCode: completeCodes,
      stepCode: stepCodeRef.current,
    });
    if (res && res.success) {
      stepCodeRef.current = null;
      cookieUtil.clearSessionStorage("_stepCode");
      jumpToDialog?.({
          type: "phoneSuccess",
          flag: "Verify successfully",
          successText: pageFlag == "addPhone" ? "Add Phone Successfully" : "Change Phone Successfully",
        });
      setIsLoading(false);
    } else {
      UKToast.show({
        icon: "error",
        content: res.msg,
      });
      setCodeError(res.msg || "Incorrect phone number");
      setIsLoading(false);
    }
  }
  const CancelFn = () => {
    jumpToDialog?.({
        type: "accountSecurity",
        flag: "accountSecurity",
      });
  };
  const getCountryList = async () => {
    const [err, res] = await getCountryListApi();
    if (res?.success) {
      countryList.current = res?.data?.internationalData?.mobileLocations;
      const v = res?.data?.internationalData;
      v?.defaultMobileLocations &&
        v?.defaultMobileLocations.length > 0 &&
        setIdPrefix(parseFloat(v?.defaultMobileLocations[0].code));
    }
    setIsPageLoading(false);
  };

  return (
    <>
      {isPageLoading ? (
        <div className={style.setPhoneLoading}>
          <LoadingIcon /> Loading...
        </div>
      ) : (
        <div className={style.email__wrap}>
          {/* 手机号输入框 */}
          {isShowVerificationInput && (
            <>
              <FormControl
                error={!!error}
                sx={{ ...InputStyle, margin: "10px 0" }}
                variant="outlined"
              >
                <OutlinedInput
                  type="text"
                  value={inputVal}
                  placeholder="Enter Phone Number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputVal(e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        autoWidth
                        value={idPrefix}
                        onChange={(event: any) => setIdPrefix(Number(event.target.value))}
                        MenuProps={MenuProps as any}
                        sx={{
                          ...SelectStyle,
                        }}
                      >
                        {countryList.current && countryList.current.length > 0 ? (
                          countryList.current.map((item: any, index: number) => {
                            return (
                              <MenuItem key={index} value={parseFloat(item.code)}>
                                {item.name}({item.code})
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value={44}>GB(+44)</MenuItem>
                        )}
                      </Select>
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      {inputVal && (
                        <IconButton
                          onClick={() => {
                            setError("");
                            setInputVal("");
                          }}
                        >
                          <CloseLine />
                        </IconButton>
                      )}
                    </InputAdornment>
                  }
                />
                {/* {!!error && <FormHelperText>{error}</FormHelperText>} */}
              </FormControl>
              {!!error && <div className={style.error__text}>{error}</div>}
            </>
          )}
          {/* 验证码盒子 */}
          {!isShowVerificationInput && (
            <>
              <div className={style.title__text}>
                We have sent the code to {!freezeAccount?.isRiskControlAccount ? maskPhone(inputVal as string) : freezeAccount?.identifier}                {
                  // 如果是风控禁止进行编辑手机号
                  !freezeAccount?.isRiskControlAccount && (
                    <>
                      <span className={style.edit} onClick={phoneEdit}>
                        Edit
                      </span>
                    </>
                  )
                }
              </div>
              <AutoCode
                error={codeError}
                onValueChange={(codes: string) => {
                  setCompleteCodes(codes);
                  if (codes.length != 6) {
                    setBtnDisabled(true);
                  } else {
                    setBtnDisabled(false);
                  }
                }}
              />
              <div style={{ width: "100%", height: "16px" }}></div>
            </>
          )}
          {/* submit 点击按钮 */}
          <div className={style.btn_emailVerify_wrap}>
            <Button
              disableElevation
              variant="contained"
              disabled={addPhoneDisabled || isLoading}
              startIcon={isLoading ? <LoadingIcon /> : null}
              onClick={phoneSignInSubmit}
              sx={{
                ...btnRedDisabled,
              }}
            >
              {pageFlag == "addPhone" && !freezeAccount?.isRiskControlAccount ? "Register" : "Continue"}
            </Button>
            <Button
              disableElevation
              variant="contained"
              disabled={sendCodeBtnDisabled}
              onClick={() => {
                if (isShowVerificationInput) {
                  CancelFn();
                } else {
                  if (!secondsFlag) {
                    sendCodeFn("Resend");
                  }
                }
              }}
              sx={{
                ...btnCancelDisabled,
              }}
            >
              {isShowVerificationInput && "Cancel"}
              {!isShowVerificationInput && secondsFlag && seconds != 0
                ? "Resend code " + resendCode
                : ""}
              {!isShowVerificationInput && seconds == 0 && "Resend"}
            </Button>
          </div>

          <div className={style.title__text}>
            After entering and verifying the Phone Number, you can login directly with your Phone
            Number.
          </div>
        </div>
      )}
    </>
  );
};

export default AddPhone;
